import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqMenuContext } from './context';
import * as styles from './menu-toggle.module.scss';

export const MenuToggle = forwardRef((props, ref) => {
  const { as, children, onClick, ...componentProps } = props;
  const Component = as;

  const context = useContext(UqMenuContext);

  return (
    <Component
      ref={ref}
      role="button"
      className={classNames(styles.root, {
        [styles.active]: context.get('open'),
      })}
      onClick={onClick}
      {...componentProps}
    >
      <span className={styles.label}>{children}</span>
      <span className={classNames('material-symbols-outlined', styles.indicator)}>
        {`expand_${context?.get('open') && !!props.onClick ? 'less' : 'more'}`}
      </span>
    </Component>
  );
});

MenuToggle.defaultProps = {
  as: 'button',
};

MenuToggle.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  onClick: PropTypes.func,
};
