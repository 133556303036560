import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './icon-button.module.scss';

UqIconButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export function UqIconButton(props) {
  return (
    <div
      className={classNames(styles.root, props.className)}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
