import React, { useRef, useEffect, useCallback, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

import { useMountEffect } from '../../../hooks/v2';

import * as styles from './filter-bar.module.scss';

const PADDING_PX = 24;

export const UqFilterBar = forwardRef(
  function FilterBar(props, ref) {
    const containerRef = useRef(null);
    // ref: array of button refs
    const refs = useRef([]);

    const [isInitialized, setInitialized] = useState(false);

    const scrollIntoView = useCallback(() => {
      containerRef.current.scrollIntoView({ block: 'end' });
    }, []);

    useImperativeHandle(ref, () => ({ scrollIntoView }));

    useEffect(() => {
      const filter = getSelectedFilter(refs, props.value);

      if (isInitialized && filter && containerRef.current) {
        const filterBounds = filter.getBoundingClientRect();
        const containerOffset = containerRef.current.scrollLeft;

        containerRef.current.scroll({
          left: containerOffset + (filterBounds.left - PADDING_PX),
          behavior: 'smooth',
        });

        props.autoScroll && scrollIntoView();
      }
    }, [props.value]);

    useMountEffect(() => setInitialized(true));

    return (
      <ButtonToolbar
        ref={containerRef}
        className={styles.toolbar}
      >
        <ButtonGroup className="chips">
          {renderOpts()}
        </ButtonGroup>
      </ButtonToolbar>
    );

    function renderOpts() {
      const opts = [];

      for (const { key, label } of props.options) {
        opts.push((
          <Button
            key={key}
            id={key}
            ref={(ref) => refs.current.push(ref)}
            onClick={() => props.onOptionSelect(key)}
            variant={key === props.value
              ? 'primary'
              : 'outline-secondary'}
          >
            {label}
          </Button>
        ))
      }

      return opts;
    }
  },
);

function getSelectedFilter(refs, value) {
  for (let i = 0; i < refs.current.length; i++) {
    if (refs.current[i]?.id === value) {
      return refs.current[i];
    }
  }

  return null;
}

UqFilterBar.propTypes = {
  autoScroll: PropTypes.bool,
  onOptionSelect: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
};

UqFilterBar.defaultProps = {
  autoScroll: true,
};
