import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from 'react-bootstrap';

import * as styles from './portrait-details.module.scss';

UqPortraitDetails.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    image: PropTypes.string,
  }),
  description: PropTypes.string,
  name: PropTypes.string,
  portraitSize: PropTypes.oneOf(['sm', 'lg']),
  role: PropTypes.string,
  src: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'right']),
};

UqPortraitDetails.defaultProps = {
  portraitSize: 'lg',
  textAlign: 'right',
};

export function UqPortraitDetails(props) {
  return (
    <section className={classNames(styles.root, props.classes?.root)}>
      {props.textAlign == 'right'
        ? (
          <>
            {renderImage()}
            {renderText()}
          </>
        )
        : (
          <>
            {renderText()}
            {renderImage()}
          </>
        )}
    </section>
  );

  function renderImage() {
    const imageClass = props.textAlign === 'left' ? styles.left : styles.right;

    return (
      <Image
        className={classNames(
          props.classes?.image,
          styles.portrait,
          styles[props.portraitSize],
          imageClass,
        )}
        src={props.src}
      />
    );
  }

  function renderText() {
    return (
      <div>
        <div className={styles.name}>
          <h3>{props.name}</h3>
          <span className={styles.role}>
            {props.role}
          </span>
        </div>
        <p className={classNames('body-3', styles.description)}>
          {props.description}
        </p>
      </div>
    );
  }
}
