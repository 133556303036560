export const SortBy = {
  ALPHABETICAL: 'alphabetical',
  POPULARITY: 'popularity',
};

export function sortIntegrationsBy(items, sortBy) {
  switch (sortBy) {
  case SortBy.ALPHABETICAL:
    return items.sort(sortByName);
  case SortBy.POPULARITY:
    return items.sort(sortByPopularity);
  default:
    return items;
  }
}

function sortByName(a, b) {
  return a.name.localeCompare(b.name);
}

function sortByPopularity(a, b) {
  return a.popularityScore - b.popularityScore;
}
