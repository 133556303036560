import { useState, useCallback } from 'react';
import { navigate } from 'gatsby';
import cloneDeep from 'lodash/cloneDeep';

import { HubspotFormService } from '../../../services/v2';
import { useQueryParams } from '../use-query-params';

const HubspotParams = {
  GCLID: 'gclid',
  UTM_ADGROUP: 'utm_adgroup',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_CONTENT: 'utm_content',
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',
  UTM_TERM: 'utm_term',
};

export function useHubspotForm(initialState, formId) {
  const { params } = useQueryParams([
    HubspotParams.UTM_SOURCE,
    HubspotParams.UTM_MEDIUM,
    HubspotParams.GCLID,
    HubspotParams.UTM_TERM,
    HubspotParams.UTM_CAMPAIGN,
    HubspotParams.UTM_CONTENT,
    HubspotParams.UTM_ADGROUP,
  ]);

  const [state, setState] = useState(cloneDeep(initialState));
  const [apiState, setApiState] = useState('ready');
  const [service] = useState(new HubspotFormService());

  const reset = useCallback(() => setState(cloneDeep(initialState)),
    [initialState, setState]);

  const setErrors = useCallback((currState, errors) => {
    const state = { ...currState };
    for (const key in errors) {
      state[key].error = errors[key];
    }

    setState(state)
  }, [state]);

  const setValue = useCallback((key, value) => {
    setState({
      ...state,
      [key]: {
        error: null,
        value,
      },
    });
  }, [state, setState]);

  const submit = useCallback(async (config) => {
    setApiState('pending');

    const payload = { ...state, ...prepareQueryParams(params) };
    const response = await service.submitForm(formId, payload);

    if (response.status === 'error') {
      setErrors(state, response.errors);
      setApiState('error');
      return;
    }

    setApiState('success');
    reset();

    if (config?.redirect) {
      navigate(config.redirect);
    }
  }, [reset, state, service, setErrors, setState, params]);

  return {
    apiState,
    reset,
    setValue,
    submit,
    state,
  }
}

function prepareQueryParams(params) {
  const values = {};

  for (const [key, value] of Object.entries(params)) {
    if (value) {
      values[key] = { value };
    }
  }

  return values;
}
