import { UqCustomerStory } from './customer-story';
import { CustomerStoryArticle } from './customer-story-article';
import { CustomerStoryHeader } from './customer-story-header';
import { CustomerStoryContent } from './customer-story-content';

export * from './customer-profile';

UqCustomerStory.Article = CustomerStoryArticle;
UqCustomerStory.Content = CustomerStoryContent;
UqCustomerStory.Header = CustomerStoryHeader;

export { UqCustomerStory };
