import React from 'react';
import PropTypes from 'prop-types';

import ChevronIcon from '@uq-assets/chevron-right.svg';
import * as styles from './carousel-controls.module.scss';

const DEFAULT_CONTROLS = [
  {
    className: styles.prev,
    id: 'back',
    Icon: ChevronIcon,
  },
  {
    className: styles.next,
    id: 'next',
    Icon: ChevronIcon,
  },
];

UqCarouselControls.propTypes = {
  controls: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      id: PropTypes.string,
      Icon: PropTypes.elementType,
      throttleMs: PropTypes.number,
    }),
  ),
  handler: PropTypes.func,
};

UqCarouselControls.defaultProps = {
  controls: DEFAULT_CONTROLS,
};

export function UqCarouselControls(props) {
  return (
    <section className={styles.controls}>
      {props.controls.map((control) => (
        <control.Icon
          key={control.id}
          className={control.className}
          onClick={() => props.handler(control.id)}
        />
      ))}
    </section>
  );
}
