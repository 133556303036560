import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { UqTextHighlight } from '../typography';

import * as styles from './stats-block.module.scss';

UqStatsBlock.propTypes = {
  classes: PropTypes.shape({
    grid: PropTypes.string,
    root: PropTypes.string,
    stat: PropTypes.string,
  }),
  description: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.exact({
        description: PropTypes.string,
        value: PropTypes.string,
      }),
    ).isRequired,
  ).isRequired,
  title: PropTypes.string,
};

export function UqStatsBlock(props) {
  return (
    <div className={classNames('new-uq-theme', props.classes?.root)}>
      {(props.title || props.description) && (
        <section className={styles.titleContainer}>
          {props.title && (
            <h2 className={styles.title}>
              <UqTextHighlight>
                {props.title}
              </UqTextHighlight>
            </h2>
          )}
          {props.description && (
            <p className="body-1">
              {props.description}
            </p>
          )}
        </section>
      )}
      <div className={classNames(styles.grid, props.classes?.grid)}>
        {props.stats.map((row, index) => (
          <React.Fragment key={index}>
            {renderRows(row)}
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  function renderRows(statRow) {
    return statRow.map((stat, index) => (
      <div
        className={classNames(styles.stat, props.classes?.stat)}
        key={index}
      >
        <span className={styles.value}>
          {stat.value}
        </span>
        <span className={classNames('body-2', styles.description)}>
          {stat.description}
        </span>
      </div>
    ));
  }
}
