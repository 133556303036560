import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import { AssetRefNodeShape, RichTextNodeShape } from '../../../models';

import * as styles from './blog-content.module.scss';

BlogContent.propTypes = {
  article: RichTextNodeShape,
  assetsRefs: PropTypes.arrayOf(AssetRefNodeShape),
};

export function BlogContent(props) {
  const renderEmbeddedAsset = useCallback((node) => {
    const targetAsset = getAssetRef(props.assetsRefs, node.data.target.sys.id);
    const image = getImage(targetAsset);

    return (
      <section className={styles.image}>
        <GatsbyImage
          image={image}
          alt="blog image"
        />
      </section>
    );
  }, [props.assetsRefs]);

  const options = useMemo(() => ({
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: renderEmbeddedAsset,
    },
  }), [renderEmbeddedAsset]);

  return (
    <div className={styles.root}>
      {documentToReactComponents(props.article, options)}
    </div>
  );
}

function getAssetRef(refs, id) {
  return refs.find(x => x.contentful_id === id);
}
