import React from 'react';

import * as styles from './customer-story.module.scss';

export function UqCustomerStory(props) {
  return (
    <article className={styles.root}>
      {props.children}
    </article>
  );
}
