import { UqMenuContext } from './context';
import { UqMenu } from './menu';
import { MenuItem } from './menu-item';
import { MenuList } from './menu-list';
import { MenuToggle } from './menu-toggle';

UqMenu.Context = UqMenuContext;

UqMenu.Item = MenuItem;
UqMenu.List = MenuList;
UqMenu.Toggle = MenuToggle;

export { UqMenu };
