import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqSvg } from '../media';

import * as styles from './integrations-row.module.scss';

UqIntegrationsRow.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.shape({
        svg: PropTypes.shape({
          content: PropTypes.string,
        }),
      }),
    }),
  ),
};

export function UqIntegrationsRow(props) {
  return (
    <section className={classNames(styles.root, props.className)}>
      {props.items.map((item, index) => (
        <div
          key={index}
          className={styles.tile}
        >
          <UqSvg className={styles.logo}>
            {item.logo.svg.content}
          </UqSvg>
        </div>
      ))}
    </section>
  );
}
