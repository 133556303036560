import React from 'react';

import { UqContainerV2 } from '@uq-components';

import * as styles from './customer-story-content.module.scss';

export function CustomerStoryContent(props) {
  return (
    <UqContainerV2 className={styles.root}>
      {props.children}
    </UqContainerV2>
  );
}
