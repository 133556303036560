import React from 'react';
import classNames from 'classnames';

import * as styles from './customer-story-article.module.scss';

export function CustomerStoryArticle(props) {
  return (
    <article className={classNames(styles.root, 'panel', 'caption-1')}>
      {props.children}
    </article>
  );
}
