import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useHover } from '@uq-hooks';

import { UqMenu } from '../../../menu';
import { UqLink } from '../../link';
import { UqNavMenuContent } from './nav-menu-content';

import * as styles from './desktop-nav-bar-item.module.scss';

UqDesktopNavBarItem.propTypes = {
  children: PropTypes.node,
  item: PropTypes.shape({
    external: PropTypes.bool,
    href: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        to: PropTypes.string,
      }),
    ),
    titles: PropTypes.shape(),
    variant: PropTypes.string,
  }),
};

export function UqDesktopNavBarItem(props) {
  const label = props.children;

  const targetRef = useRef(null);
  const { isHovered, hoverTriggers } = useHover();

  return (
    <li
      className={classNames(styles.root, { [styles.hovered]: isHovered })}
      ref={targetRef}
      {...hoverTriggers}
    >
      {props.item.links?.length ? renderMenu() : renderLink()}
    </li>
  );

  function renderMenu() {
    return (
      <UqMenu open={isHovered}>
        <UqMenu.Toggle
          as={UqLink}
          to={props.item.href || props.item.links?.[0]?.href}
          external={props.item.external}
          hideArrow
          variant="inherit"
        >
          <span className={styles.label}>
            {label}
          </span>
        </UqMenu.Toggle>

        <UqNavMenuContent
          cta={props.item.cta}
          links={props.item.links}
          target={targetRef}
          titles={props.item.titles}
          variant={props.item.variant}
        >
          {props.item.spotlight ? props.item.spotlight : null}
          {props.item.expansion ? props.item.expansion : null}
        </UqNavMenuContent>
      </UqMenu>
    );
  }

  function renderLink() {
    return (
      <UqLink
        to={props.item.href}
        external={props.item.external}
        hideArrow
        variant="inherit"
      >
        <span className={styles.label}>
          {label}
        </span>
      </UqLink>
    );
  }
}
