import { useContext } from 'react';

import { UqVideoManagerContext } from '@uq-components';

export function useVideoManager() {
  const context = useContext(UqVideoManagerContext);

  const activate = (id) => {
    const current = context.get('active');

    context.update('active', id);

    if (!current || current === id) {
      return;
    }

    for (const [key, video] of context.get('registration')) {
      if (current === key) {
        video.current?.getInternalPlayer()?.pause();
      }
    }
  };

  const register = (id, ref) => context.get('registration')?.set(id, ref);
  const unregister = (id) => context.get('registration')?.delete(id);

  return {
    activate,
    register,
    unregister,
  }
}
