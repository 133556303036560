import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { integrationShape } from '../../../models';
import { UqFade } from '../layout/animation';
import { UqIntegrationsRow } from './integrations-row';

import * as styles from './integration-tiles.module.scss';

UqIntegrationTiles.propTypes = {
  tiles: PropTypes.arrayOf(integrationShape),
};

export function UqIntegrationTiles(props) {
  const [animate, setAnimate] = useState(false);
  const [left, right] = [props.tiles.slice(0, 5), props.tiles.slice(5)];

  return (
    <UqFade
      onEnter={() => {
        if (!animate) {
          setAnimate(true);
        }
      }}
    >
      <section className={styles.root}>
        <UqIntegrationsRow
          className={
            classNames(
              styles.left,
              { [styles.animate]: animate },
            )}
          items={left}
        />
        <UqIntegrationsRow
          className={
            classNames(styles.right,
              { [styles.animate]: animate },
            )}
          items={right}
        />
      </section>
    </UqFade>
  );
}
