import PropTypes from 'prop-types';

export const heroShape = PropTypes.shape({
  media: PropTypes.shape({
    url: PropTypes.string,
  }),
  pageId: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
});
