import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqAccordionContext } from '../context';
import { UqExpansionPanelContext } from './context';

import * as styles from './expansion-panel-summary.module.scss';

ExpansionPanelSummary.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export function ExpansionPanelSummary(props) {
  const context = useContext(UqExpansionPanelContext);
  const accordionContext = useContext(UqAccordionContext);

  const isExpanded = getExpansionState();

  return (
    <div
      role="heading"
      aria-level="1"
      id={`${context.get('id')}-summary`}
    >
      <button
        className={classNames('uq-expansion-panel-summary', styles.root)}
        type="button"
        aria-expanded={isExpanded}
        aria-controls={`${context.get('id')}-content`}
      >
        {props.children}
      </button>
    </div>
  );

  function getExpansionState() {
    return accordionContext.get ? accordionContext.get('activeKey') === props.eventKey : context.get('expanded');
  }
}
