import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as styles from './avatar.module.scss';

UqAvatar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
};

export function UqAvatar(props) {
  return (
    <div className={classNames(styles.root, props.className)}>
      {props.children}
    </div>
  );
}
