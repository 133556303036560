import React from 'react';
import PropTypes from 'prop-types';
import sanitize from 'sanitize-html';

import { SANITIZER_CONFIG } from '../../../helpers/v2';

UqSvg.propTypes = {
  className: PropTypes.string,
  /**
   * The SVG content as a string to be embedded into the page.
   */
  children: PropTypes.string,
};

export function UqSvg(props) {
  return (
    <div
      className={props.className}
      dangerouslySetInnerHTML={{
        __html: sanitize(props.children, SANITIZER_CONFIG['svg']),
      }}
    />
  );
}
