import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqLink } from '../../../navigation';

import * as styles from './video-trigger.module.scss';

VideoTrigger.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
}

export function VideoTrigger(props) {
  return (
    <UqLink
      onClick={props.onClick}
      hideArrow
      variant="inherit"
    >
      <span className={classNames('material-symbols-outlined', styles.playIcon)}>
        play_circle
      </span>
      <span className="body-2-strong">
        {props.children}
      </span>
    </UqLink>
  );
}
