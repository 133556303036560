import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqTypography } from '../../typography';
import { UqExpansionPanel } from './expansion-panel';
import { UqAccordionContext } from './context';

import * as styles from './accordion-panel.module.scss';

AccordionPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  eventKey: PropTypes.string,
  id: PropTypes.string,
  summary: PropTypes.string,
};

export function AccordionPanel(props) {
  const context = useContext(UqAccordionContext);

  return (
    <UqExpansionPanel
      className={styles.expansionPanel}
      eventKey={props.eventKey}
      id={props.id}
    >
      <UqExpansionPanel.Summary>
        <UqTypography
          as="h3"
          className={
            classNames(styles.summary, {
              [styles.inactive]: context.get('activeKey') !== props.eventKey,
            })
          }
        >
          {props.summary}
        </UqTypography>
      </UqExpansionPanel.Summary>
      <UqExpansionPanel.Content>
        {props.children}
      </UqExpansionPanel.Content>
    </UqExpansionPanel>
  );
}
