import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';

import { UqSvg } from '../../media';

import * as styles from './customer-logos.module.scss';

const query = graphql`
  query {
    customers: contentfulContentGroup(slug: {eq: "customer-logos"}) {
      content {
        ... on ContentfulCustomer {
          logo {
            svg {
              content: originalContent
            }
          }
        }
      }
    }
  }
`;

export function UqCustomerLogosV2() {
  const { customers } = useStaticQuery(query);

  return (
    <div className={styles.root}>
      <span className={classNames('caption-1', styles.title)}>
        Trusted by
      </span>
      <ul className={styles.list}>
        {customers.content.map((customer, index) => (
          <li
            key={index}
            className={styles.logo}
          >
            <UqSvg>
              {customer.logo.svg.content}
            </UqSvg>
          </li>
        ))}
      </ul>
    </div>
  );
}
