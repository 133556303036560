import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './hero-bleeding.module.scss';

UqHeroBleeding.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  image: PropTypes.string.isRequired,
  renderActions: PropTypes.func,
  renderTitle: PropTypes.func,
  subtitle: PropTypes.string.isRequired,
};

export function UqHeroBleeding(props) {
  const rootClasses = classNames(styles.root, props.classes?.root);

  return (
    <div className={rootClasses}>
      <div className={styles.titleCol}>
        {props.renderTitle()}
        <p className={classNames('body-2', styles.subtitle)}>
          {props.subtitle}
        </p>
        {props.renderActions?.()}
      </div>
      <div className={styles.imageCol}>
        <video className={styles.video} autoPlay="1" muted playsInline>
          <source src="/images/hero-video/unitq_animation.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
