import React from 'react';
import PropTypes from 'prop-types';
import sanitize from 'sanitize-html';

const DEFAULT_CONFIG = {
  allowedAttributes: false,
  /** Only supporting basic text by default */
  allowedTags: ['a', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'q'],
}

UqHtmlSanitizer.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.string,
  config: PropTypes.shape(),
};

export function UqHtmlSanitizer(props) {
  const { as: Component = 'span' } = props;

  const sanitized = sanitize(props.children, props.config || DEFAULT_CONFIG)

  return (
    <Component
      dangerouslySetInnerHTML={{ __html: sanitized }}
    />
  );
}
