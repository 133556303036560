export * from './figure-accordion';

import { UqFigureActions } from './figure-actions';
import { UqFigureContent } from './figure-content';
import { UqFigureImage } from './figure-image';
import { UqFigureRow } from './figure-row';
import { UqFigureTitle } from './figure-title';

const UqFigure = UqFigureRow;

UqFigure.Actions = UqFigureActions;
UqFigure.Content = UqFigureContent;
UqFigure.Image = UqFigureImage;
UqFigure.Title = UqFigureTitle;

export { UqFigure };
