import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';

import ClearIcon from './assets/clear.svg';
import SearchIcon from './assets/search.svg';

import * as styles from './search-bar.module.scss';
import { UqIconButton } from '../button/icon-button';

UqSearchBar.propTypes = {
  info: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'rounded']),
};

export function UqSearchBar(props) {
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  const onInputChange = useCallback((v) => {
    props.onChange?.(v);

    setValue(v);
  }, [props.onChange]);

  const clearInput = useCallback(() => onInputChange(''), [onInputChange]);
  const focusInput = useCallback(() => inputRef.current?.focus(), []);
  const handleInput = useCallback((evt) => onInputChange(evt.target.value), [onInputChange]);

  return (
    <article className={styles.root}>
      <section className={styles.content}>
        <SearchIcon className={styles.searchIcon} />
        {!value && (
          <span
            onClick={focusInput}
            className={styles.placeholder}
          >
            <em>{props.placeholder}</em>
          </span>
        )}
        <Form.Control
          ref={inputRef}
          className={classNames('search', { 'rounded-input': props.variant === 'rounded' })}
          type="text"
          onChange={handleInput}
          value={props.value || value}
        />
        {!!value.length && (
          <UqIconButton
            className={styles.clearIcon}
            onClick={clearInput}
          >
            <ClearIcon />
          </UqIconButton>
        )}
        {props.info && (
          <Form.Text muted>
            <span>
              <em>{props.info}</em>
            </span>
          </Form.Text>
        )}
      </section>
    </article>
  );
}
