import React from 'react';

import { UqLink } from '../link';
import { Content } from './content';

import * as styles from './footer-nav-resources.module.scss';

export function UqFooterNavResources() {
  return (
    <ul className={styles.root}>
      {Content.resources.map((v, index) => {
        const Component = v.component;

        return (
          <li key={index}>
            {Component
              ? (
                <Component
                  key={index}
                  className="caption-2"
                />
              )
              : (
                <UqLink
                  className="caption-2"
                  external={v.external}
                  to={v.href}
                  hideArrow
                  variant="inherit"
                >
                  {v.label}
                </UqLink>
              )}
          </li>
        );
      })}
    </ul>
  );
}
