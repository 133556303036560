const HUBSPOT_URL = 'https://api.hsforms.com/submissions/v3/integration/submit/6459692';

export class HubspotFormService {
  async submitForm(formId, formData) {
    const url = `${HUBSPOT_URL}/${formId}`;
    const config = {
      body: this._createPayload(formData),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    };

    const response = await fetch(url, config);
    const data = await response.json();

    if (!response.ok) {
      return {
        errors: this._handleErrors(formData, data.errors || []),
        status: 'error',
      };
    }

    return { status: 'success' };
  }

  _createPayload(data) {
    const fields = [];

    for (const key in data) {
      const entry = data[key];
      if (entry) {
        fields.push({ name: key, value: entry.value });
      }
    }

    return JSON.stringify({ fields });
  }

  _handleErrors(formData, errors) {
    const errorState = {};

    for (const key in formData) {
      const error = errors.find(({ message }) => message.includes(`fields.${key}`));
      if (error) {
        errorState[key] = error.errorType;
      }
    }

    return errorState;
  }
}
