import React, { useRef, useCallback, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Button, Modal } from 'react-bootstrap';

import { Content } from '../../../content/v2/request-demo';
import { UqLink } from '../navigation/link';
import { UqRequestDemoForm } from '../forms/request-demo-form';

import * as styles from './request-demo-button.module.scss';

UqLegacyRequestDemoButton.propTypes = {
  buttonProps: PropTypes.shape({
    variant: PropTypes.string,
  }),
  children: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'link']),
};

UqLegacyRequestDemoButton.defaultProps = {
  buttonProp: {
    variant: 'primary',
  },
  type: 'button',
};

export function UqLegacyRequestDemoButton(props) {
  const formRef = useRef(null);
  const [showDemoModal, toggleDemoModal] = useState(false);

  const breakpoint = useBreakpoint();

  const closeModal = useCallback(() => {
    toggleDemoModal(false);
    formRef.current.reset();
  }, [toggleDemoModal, formRef]);

  const formClasses = breakpoint.lg ? {
    formContainer: styles.desktopForm,
    imageContainer: styles.desktopImage,
  } : {
    root: styles.tabletForm,
    imageContainer: styles.tabletImageContainer,
  }

  return (
    <>
      {props.type === 'button' && (
        <Button
          className={props.className}
          onClick={() => toggleDemoModal(true)}
          variant={props.buttonProps?.variant}
        >
          {props.children || 'Get a Demo'}
        </Button>
      )}
      {props.type === 'link' && (
        <UqLink onClick={() => toggleDemoModal(true)}>
          {props.children || 'Get a Demo'}
        </UqLink>
      )}
      <Modal
        className="new-uq-theme"
        show={showDemoModal}
        onHide={closeModal}
        centered
        keyboard
        size="xl"
      >
        <Modal.Header className={styles.header} closeButton></Modal.Header>

        <UqRequestDemoForm
          classes={formClasses}
          ref={formRef}
          subtitle={Content.subtitle}
          wide={breakpoint.lg}
        />
      </Modal>
    </>
  )
}
