import UpworkLogo from '../assets/logos/upwork.svg';

export const Content = {
  caption: 'Unlock customer feedback to drive business results',
  quote: {
    author: {
      avatar: UpworkLogo,
      name: 'Todd Ranson',
      role: 'Sr. Director, \n Customer 360°',
    },
    text: `We've been leaning into unitQ to understand what our customers \n are saying at scale, 
    separate out signals from noise and expedite \n getting insights into the hands of the key decision makers.`,
  },
  stats: [
    {
      description: 'Decrease in \n user churn',
      value: '50%',
    },
    {
      description: 'Reduction in \n support tickets',
      value: '40%',
    },
    {
      description: 'Boost in \n NPS',
      value: '18%',
    },
  ],
  title: 'Understand your users \n - in real time!',
  subtitle: `Discover how AI\u2011powered insights from user feedback can help you craft 
    high\u2011quality products, services, and experiences to drive growth.`,
};
