import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './customer-profile-section.module.scss';

CustomerProfileSection.propTypes = {
  title: PropTypes.string,
};

export function CustomerProfileSection(props) {
  return (
    <div className={styles.root}>
      <span className="caption-2">
        {props.title}
      </span>
      <div className="caption-1">
        {props.children}
      </div>
    </div>
  );
}
