import { useCallback } from 'react';

const HUBSPOT_REGION = 'na1';
const HUBSPOT_PORTAL = '6459692';

export function useEmbeddedForm(formId, target) {
  return useCallback(() => {
    if (!window) {
      return;
    }

    window.hbspt?.forms.create({
      region: HUBSPOT_REGION,
      portalId: HUBSPOT_PORTAL,
      formId,
      target: `#${target}`,
    });
  }, [formId, target]);
}
