import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import { UqLogoBackground } from '../../unitq-logo';

import * as styles from './container.module.scss';

UqContainer.propTypes = {
  background: PropTypes.oneOf(['light', 'dark']),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  logoProps: PropTypes.shape({
    anchor: PropTypes.oneOf([
      'bottomLeft',
      'bottomRight',
      'topLeft',
      'topRight',
    ]),
    dark: PropTypes.bool,
  }),
  overflowX: PropTypes.bool,
  overflowY: PropTypes.bool,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
  theme: PropTypes.oneOf([
    'new-uq-theme',
    'legacy-blog-theme',
  ]),
  variant: PropTypes.oneOf(['default', 'wide']),
};

UqContainer.defaultProps = {
  fullWidth: false,
  overflowX: false,
  overflowY: false,
  refProp: null,
  theme: 'new-uq-theme',
  variant: 'default',
};

export function UqContainer(props) {
  const isDark = props.background === 'dark';
  const isWideVariant = props.variant === 'wide';

  return (
    <Container
      ref={props.refProp}
      className={classNames(props.theme, props.className, {
        'wide': isWideVariant,
        [styles.background]: !!props.background,
        [styles.dark]: isDark,
        [styles.overflowX]: props.overflowX,
        [styles.overflowY]: props.overflowY,
      })}
      fluid={props.fullWidth}
    >
      {props.logoProps ? (
        <UqLogoBackground
          className={props.className}
          {...props.logoProps}
        >
          <>
            {props.children}
          </>
        </UqLogoBackground>
      ) : props.children}

    </Container>
  )
}
