import React from 'react';
import classNames from 'classnames';

import { Content } from './content';
import { UqMobileNavV2 } from './mobile';
import { UqDesktopNavbarV2 } from './desktop';

import * as styles from './nav-bar.module.scss';

export function UqNavbarV2() {
  return (
    <>
      <UqDesktopNavbarV2>
        {Content.items.map((v, index) => (
          <UqDesktopNavbarV2.Item
            key={index}
            item={v}
          >
            {v.label}
          </UqDesktopNavbarV2.Item>
        ))}
      </UqDesktopNavbarV2>
      <UqMobileNavV2>
        {Content.items.map((v, index) => (
          <UqMobileNavV2.Item
            key={index}
            id={v.label.toUpperCase()}
            className={classNames({
              [styles.end]: index === Content.items.length - 1,
            })}
            item={v}
          >
            {v.label}
          </UqMobileNavV2.Item>
        ))}
      </UqMobileNavV2>
    </>
  );
}
