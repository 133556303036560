import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MenuTransition } from './menu-transition';

import * as styles from './menu-list.module.scss';

MenuList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

export function MenuList(props) {
  const { children, className, ...componentProps } = props;

  return (
    <MenuTransition>
      <ul
        className={classNames(styles.root, className)}
        {...componentProps}
      >
        {children}
      </ul>
    </MenuTransition>
  );
}
