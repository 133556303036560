import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useScroll } from '@uq-hooks';
import * as styles from './scroll-content.module.scss';

UqScrollContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /** Scroll distance from the top of the container in pixels to trigger render */
  thresholdPx: PropTypes.number,
};

/** Renders content based on the scroll event and threshold distance of scroll */
export function UqScrollContent(props) {
  const isThresholdMet = useScroll(props.thresholdPx);

  return (
    <div
      className={classNames(styles.root, {
        [styles.active]: isThresholdMet,
      })}
    >
      {props.children}
    </div>
  );
}
