import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import { isExternalUrl } from '@uq-helpers';
import { actionShape } from '../../../../models';
import { UqLink } from '../../navigation';

import * as styles from './quick-action-tile.module.scss';

UqQuickActionTile.propTypes = {
  action: actionShape,
  caption: PropTypes.string,
  classes: PropTypes.shape({
    image: PropTypes.string,
    root: PropTypes.string,
  }),
  dark: PropTypes.bool,
  imageVariant: PropTypes.oneOf(['default', 'background']),
  renderAction: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  src: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

UqQuickActionTile.defaultProps = {
  dark: false,
  imageVariant: 'default',
  size: 'lg',
}

export function UqQuickActionTile(props) {
  return (
    <article
      className={
        classNames(
          styles.root,
          styles[props.size],
          props.classes?.root,
          {
            [styles.dark]: props.dark,
            [styles.split]: !!props.src,
          },
        )
      }
      style={props.imageVariant === 'background'
        ? { backgroundImage: `url(${props.src})` }
        : undefined
      }
    >
      {props.imageVariant === 'default' && props.src && (
        <Image
          className={classNames(styles.image, props.classes?.image, styles[props.size])}
          src={props.src}
        />
      )}
      <section className={classNames(styles.content, styles[props.size])}>
        {props.caption && (
          <div className={styles.caption}>
            {props.caption}
          </div>
        )}
        <h2 className={classNames(styles.title, styles[props.size])}>
          {props.title}
        </h2>
        {props.subtitle && (
          <p className="body-2">
            {props.subtitle}
          </p>
        )}
        {props.renderAction && props.renderAction()}
        {props.action && (
          <UqLink
            to={props.action.href}
            external={props.action.href && isExternalUrl(props.action.href)}
          >
            {props.action.label}
          </UqLink>
        )}
      </section>
    </article>
  );
}
