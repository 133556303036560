import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqLink, UqHeroV2, UqContainerV2 } from '@uq-components';
import LinkedInIcon from '@uq-assets/social/linked-in-square.svg';
import TwitterIcon from '@uq-assets/social/twitter-square.svg';

import * as styles from './customer-story-header.module.scss';

CustomerStoryHeader.propTypes = {
  backgroundSrc: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    background: PropTypes.string,
  }),
};

export function CustomerStoryHeader(props) {
  return (
    <UqHeroV2 className={styles.root}>
      <header
        className={classNames(props.classes?.background, styles.background)}
        style={{ backgroundImage: `url(${props.backgroundSrc})` }}
      >
        <UqContainerV2
          fill
          theme="dark"
        >
          <div className={styles.title}>
            {props.children}
          </div>
        </UqContainerV2>
        <UqContainerV2 className={styles.socials}>
          {renderSocial({
            href: 'https://www.linkedin.com/company/unitq/',
            icon: LinkedInIcon,
          })}
          {renderSocial({
            href: 'https://twitter.com/unitqsoftware/',
            icon: TwitterIcon,
          })}
        </UqContainerV2>
      </header>
    </UqHeroV2>
  );

  function renderSocial(config) {
    const Icon = config.icon;

    return (
      <UqLink
        className={styles.link}
        to={config.href}
        external
        hideArrow
      >
        <Icon className={styles.icon} />
      </UqLink>
    );
  }
}
