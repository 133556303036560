import set from 'lodash/set';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

/** Updates an immutable state object with new data by path */
export function updateContext(context, path, value) {
  return set(cloneDeep(context), path, value);
}

export function createContextState(defaultState, overrides) {
  return merge({}, defaultState, overrides || {});
}
