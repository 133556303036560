import React from 'react';
import PropTypes from 'prop-types';

import { Content } from './content';
import { UqValuesTile } from './values-tile';

import * as styles from './values-block.module.scss';
import { UqTextHighlight } from '../typography';

UqValuesBlock.propTypes = {
  variant: PropTypes.oneOf(['default', 'condensed']),
};

export function UqValuesBlock(props) {
  const isCondensed = props.variant === 'condensed';

  return (
    <section className={styles.root}>
      <h1>
        <UqTextHighlight>
          {Content.title}
        </UqTextHighlight>
      </h1>
      <div className={styles.grid}>
        {Content.values.map((value, index) => (
          <UqValuesTile
            key={index}
            condensed={isCondensed}
            {...value}
          />
        ))}
      </div>
    </section>
  );
}
