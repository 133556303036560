export const MarkdownRegex = {
  BOLD: /\*\*(.*)\*\*/, // e.g. This text is **bold**
  HIGHLIGHT: /!-(.*)-!/, // e.g. This text is !-highlighted-!
  ITALIC: /\*(.*)\*/, // e.g. This text is *italic*
  LINE_BREAK: /<br>/,
};

export function buildHtmlTemplate(tagName, config) {
  const className = config?.className ? `class="${config.className}"` : '';

  return `<${tagName} ${className}>$1</${tagName}>`
}
