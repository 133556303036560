import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import { useUqContext } from '@uq-hooks';
import { UqAccordionContext } from '../context';
import { UqExpansionPanelContext, initialState } from './context';

import * as styles from './expansion-panel.module.scss';

UqExpansionPanel.propTypes = {
  as: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
};

export function UqExpansionPanel(props) {
  const ref = useRef(null);
  const breakpoint = useBreakpoint();

  const context = useUqContext(initialState, { id: props.id });
  const accordionContext = useContext(UqAccordionContext);

  const isExpanded = getExpansionState(accordionContext);

  const scrollToElement = () => {
    const scroll = () => {
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: ref.current.offsetTop - 80,
      });

      ref.current.removeEventListener('transitionend', scroll);
    };

    ref.current.addEventListener('transitionend', scroll);
  };

  const toggleExpansion = () => {
    context.update('expanded', !context.get('expanded'));
    accordionContext.update?.('activeKey', props.eventKey);

    accordionContext.api?.onActivated(props.eventKey);

    if (breakpoint.xs) {
      scrollToElement();
    }
  };

  return (
    <UqExpansionPanelContext.Provider value={context}>
      <section
        ref={ref}
        className={classNames(styles.root,
          props.className, {
            [styles.active]: isExpanded,
          })
        }
        role="region"
        aria-labelledby={`${props.id}-summary`}
        onClick={toggleExpansion}
      >
        {props.children}
      </section>
    </UqExpansionPanelContext.Provider>
  );

  function getExpansionState(accordionContext) {
    return accordionContext.get ? accordionContext.get('activeKey') === props.eventKey : isExpanded;
  }
}

