import React, { useCallback, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Button, Form, Col, Spinner, Alert } from 'react-bootstrap';

import { useHubspotForm, useMountEffect } from '../../../hooks/v2';
import { Content } from './content';

import DemoImage from './assets/demo.svg';
import * as styles from './request-demo-form.module.scss';

const FORM_ID = '295d7b3a-9a82-499a-892e-be2bf87541e8';

const INITIAL_STATE = {
  company: { error: null, value: '' },
  email: { error: null, value: '' },
  firstname: { error: null, value: '' },
  jobtitle: { error: null, value: '' },
  lastname: { error: null, value: '' },
};

export const UqRequestDemoForm = forwardRef(
  function RequestDemoForm(props, ref) {
    const { xs } = useBreakpoint();
    const hubspotForm = useHubspotForm(INITIAL_STATE, FORM_ID);
    const { apiState, reset, setValue, state, submit } = hubspotForm;

    const emailRef = useRef(null);

    const submitForm = useCallback(async (evt) => {
      evt.preventDefault();

      await submit({ redirect: '/request-demo-thank-you' });
    }, [submit]);

    useImperativeHandle(ref, () => ({ reset }));

    useMountEffect(() => {
      if (!xs) {
        emailRef.current?.focus({ preventScroll: true });
      }
    });

    const rootClasses = classNames(props.classes?.root, styles.root, { [styles.wide]: props.wide });
    const formContainerClasses = classNames(props.classes?.formContainer, styles.formContainer, { [styles.wide]: props.wide });
    const imageContainerClasses = classNames(props.classes?.imageContainer, styles.imageContainer, { [styles.wide]: props.wide });
    const imageClasses = classNames(props.classes?.image, styles.demoImage);

    const showSubtitle = !!props.subtitle && props.wide;

    return (
      <section className={rootClasses}>
        <div className={imageContainerClasses}>
          <DemoImage className={imageClasses} />
        </div>
        <div className={formContainerClasses}>
          {renderSubtitle()}
          <Form>
            {renderField('Work Email*', 'email', emailRef)}
            {renderNameFields()}
            {renderField('Company Name*', 'company')}
            {renderField('Job Title*', 'jobtitle')}
            <Button
              className={styles.submitBtn}
              disabled={apiState === 'pending'}
              onClick={submitForm}
              type="submit"
            >
              {apiState !== 'pending'
                ? (
                  <span>Schedule a Demo</span>
                )
                : (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                  />
                )}
            </Button>
            {apiState === 'error' && (
              <Alert
                className={styles.errorMessage}
                variant="danger"
              >
                There was a problem submitting the form.
              </Alert>
            )}
          </Form>
        </div>
      </section>
    );

    function renderHeading() {
      const headingClasses = classNames(props.classes?.heading, { [styles.title]: !props.wide });

      return (
        <h2 className={headingClasses}>
          Get a Demo
        </h2>
      );
    }

    function renderSubtitle() {
      const subtitleClasses = classNames('body-1', styles.subtitle);

      return (
        <section>
          {renderHeading()}
          {showSubtitle && (
            <p className={subtitleClasses}>
              {props.subtitle}
            </p>
          )}
        </section>
      );
    }

    function renderNameFields() {
      if (props.wide) {
        return (
          <Form.Row>
            <Col>{renderField('First Name*', 'firstname')}</Col>
            <Col>{renderField('Last Name*', 'lastname')}</Col>
          </Form.Row>
        );
      }

      return (
        <>
          {renderField('First Name*', 'firstname')}
          {renderField('Last Name*', 'lastname')}
        </>
      );
    }

    function renderField(label, key, ref) {
      const field = state[key];

      return (
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Form.Control
            ref={ref}
            required
            type="text"
            isInvalid={!!field.error}
            value={field.value}
            onChange={(evt) => setValue(key, evt.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {Content.errorMessages[field.error] || 'Please enter a valid value.'}
          </Form.Control.Feedback>
        </Form.Group>
      );
    }
  },
);

UqRequestDemoForm.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    formContainer: PropTypes.string,
    heading: PropTypes.string,
    image: PropTypes.string,
    imageContainer: PropTypes.string,
  }),
  subtitle: PropTypes.string,
  wide: PropTypes.bool,
};

UqRequestDemoForm.defaultProps = {
  wide: false,
};
