import { useCallback } from 'react';

export function useExternalScript(id, resourceUrl) {
  const loadScript = useCallback(() => {
    if (!window || !window.document) {
      return;
    }

    const loadedScript = getScriptById(id);

    if (loadedScript) {
      return;
    }

    const script = window.document.createElement('script');

    script.id = id;
    script.src = resourceUrl;
    script.async = true;

    document.body.appendChild(script);

    return script;
  }, []);

  const cleanupScript = useCallback(() => {
    const script = getScriptById(id);

    if (script) {
      script.remove();
    }
  }, []);

  return { loadScript, cleanupScript };
}

function getScriptById(id) {
  return window.document.querySelector(id);
}
