import React from 'react';
import PropTypes from 'prop-types';

import { UqScrollRevealAnimation } from './scroll-reveal-animation';

UqSlide.propTypes = {
  ...UqScrollRevealAnimation.propTypes,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
};

UqSlide.defaultProps = {
  delay: 150,
  direction: 'up',
  duration: 1000,
  easing: 'ease',
};

export function UqSlide(props) {
  return (
    <UqScrollRevealAnimation
      type={`slide-${props.direction}`}
      {...props}
    >
      {props.children}
    </UqScrollRevealAnimation>
  );
}
