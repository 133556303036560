import React, { useContext, forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useHover, useUqContext, useScroll } from '@uq-hooks';
import { UqLayoutContext } from '../layout';
import { UqAppbarContext, initialState } from './context';

import * as styles from './app-bar.module.scss';
import { isSSR } from '@uq-helpers';

export const UqAppBarV2 = forwardRef((props, ref) => {
  const isScrolled = useScroll(0);
  const { isHovered, hoverTriggers } = useHover();

  const context = useUqContext(initialState, {
    isHovered,
    isScrolled,
  });

  const layoutContext = useContext(UqLayoutContext);

  const isActive = context.get('active');
  const isRaised = !props.static && (isActive || isHovered || isScrolled);

  return (
    <UqAppbarContext.Provider value={context}>
      <header
        ref={ref}
        {...hoverTriggers}
        className={classNames(styles.root, props.className, {
          [styles.active]: isActive,
          [styles.dark]: layoutContext.get('theme') === 'dark',
          [styles.raised]: !isSSR() && isRaised,
          [styles.scrolled]: !isSSR() && isScrolled,
        })}
      >
        <div className="uq-app-bar">
          {props.children}
        </div>
      </header>
    </UqAppbarContext.Provider>
  );
});

UqAppBarV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  /** Disables the active event triggers */
  static: PropTypes.bool,
};

UqAppBarV2.defaultProps = {
  static: false,
};
