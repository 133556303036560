import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './stats-row.module.scss';

UqStatsRow.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  separate: PropTypes.bool,
  stats: PropTypes.arrayOf(
    PropTypes.exact({
      description: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export function UqStatsRow(props) {
  const showSeparator = !!props.separate;
  const rootClasses = classNames(props.classes?.root, styles.root);

  return (
    <div className={rootClasses}>
      {props.stats.map((stat, index) => (
        <React.Fragment key={index}>
          <div className={styles.stat} key={index}>
            <span className={styles.value}>
              {stat.value}
            </span>
            <span className={styles.description}>
              {stat.description}
            </span>
          </div>
          {showSeparator && renderSeparator(index)}
        </React.Fragment>
      ))}
    </div>
  );

  function renderSeparator(index) {
    if (props.stats.length - 1 === index) {
      return null;
    }

    return <div className={styles.separator}></div>
  }
}
