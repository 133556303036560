import React from 'react';

import * as styles from './dialog-container.module.scss';

export const DIALOG_CONTAINER_ID = 'uq-dialog-portal';

export function UqDialogContainer() {
  return (
    <div
      id={DIALOG_CONTAINER_ID}
      className={styles.root}
    />
  );
}
