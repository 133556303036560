import FacebookIcon from '@uq-assets/social/facebook.svg';
import LinkedInIcon from '@uq-assets/social/linked-in.svg';
import TwitterIcon from '@uq-assets/social/twitter.svg';

export const Content = {
  social: [
    { href: 'https://www.linkedin.com/company/unitq/about/', icon: LinkedInIcon, id: 'linked-in' },
    { href: 'https://twitter.com/unitqsoftware/', icon: TwitterIcon, id: 'twitter' },
    { href: 'https://www.facebook.com/unitQsoftware/', icon: FacebookIcon, id: 'facebook' },
  ],
};

