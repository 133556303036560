import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';

import { UqVideoManager } from '@uq-components';

/** @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-ssr/#wrapRootElement  */
export const wrapRootElement = ({ element }) => {
  return (
    <>
      <UqVideoManager>
        {element}
      </UqVideoManager>
      <Script
        key="cookie-law"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="1db33f97-82f2-422d-82ea-11712abfc848"
        strategy={ScriptStrategy.idle}
      />
    </>
  );
};
