import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'react-bootstrap';

import { UqFlexRow } from '../layout';
import { UqLegacyRequestDemoButton } from './legacy-request-demo-button';
import { Content } from './content';

import * as styles from './request-demo-banner.module.scss';

UqRequestDemoBanner.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    row: PropTypes.string,
  }),
};

export function UqRequestDemoBanner(props) {
  const Image = Content.image;

  return (
    <UqFlexRow
      breakpoint="md"
      classes={{
        root: props.classes?.root,
        row: classNames('new-uq-theme', styles.root, props.classes?.row),
      }}
    >
      <Col xl={6} md={6} sm={12}>
        <div className={styles.caption}>
          <h2 className={styles.title}>
            {Content.title}
          </h2>
          <p className={classNames('body-3', styles.desc)}>
            {Content.subtitle}
          </p>
          <UqLegacyRequestDemoButton
            className={classNames('extra-wide', styles.button)}
            buttonProps={{ variant: 'dark' }}
          >
            {Content.actionText}
          </UqLegacyRequestDemoButton>
        </div>
      </Col>
      <Col xl={6} md={6} sm={12}>
        <div className={styles.imageCol}>
          <Image />
        </div>
      </Col>
    </UqFlexRow>
  );
}
