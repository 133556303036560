import React, { useState, useRef, forwardRef, useCallback, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useInterval } from '../../../hooks/v2';
import { UqCarouselControls } from './carousel-controls';
import { throttledBack, throttledNext } from './control-helper';

import * as styles from './carousel.module.scss';

export const UqCarousel = forwardRef(
  function Carousel(props, ref) {
    const stageRef = useRef(null);

    const [displayItems, setDisplayItems] = useState([...props.children]);

    const {
      state: intervalState,
      controls: intervalControls,
    } = useInterval(() => handleControls('next'));

    const handleControls = useCallback((id) => {
      if (intervalState === 'stopped') {
        intervalControls.play();
        props.onPlay();
      }

      if (id === 'back') {
        throttledBack(displayItems, setDisplayItems, stageRef);
      }

      if (id === 'next') {
        throttledNext(displayItems, setDisplayItems, stageRef);
      }
    }, [displayItems, intervalControls, setDisplayItems, props.onPlay]);

    useImperativeHandle(ref, () => ({ controls: intervalControls }));

    return (
      <article
        className={styles.root}
        onMouseEnter={intervalControls.pause}
        onMouseLeave={intervalControls.resume}
      >
        <section className={styles.stageContainer}>
          <section
            ref={stageRef}
            className={styles.stage}
          >
            {displayItems.map((item, index) => (
              <div
                key={index}
                className={classNames(styles.item, { [styles.inactive]: index !== 0 })}
              >
                {item}
              </div>
            ))}
          </section>
          <div className={styles.overlay} />
        </section>
        {props.renderControls
          ? props.renderControls()
          : <UqCarouselControls handler={handleControls} />
        }
      </article>
    );
  },
);

UqCarousel.propTypes = {
  children: PropTypes.array,
  /** Triggers when the carousel interval state is set to 'playing' from 'stopped' */
  onPlay: PropTypes.func,
  renderControls: PropTypes.func,
};
