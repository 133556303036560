import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, Nav } from 'react-bootstrap';

import { UqScore } from './score';

import * as styles from './ranking-item.module.scss';

UqRankingItem.propTypes = {
  href: PropTypes.string,
  logo: PropTypes.string,
  rank: PropTypes.number,
  score: PropTypes.number,
  title: PropTypes.string,
};

export function UqRankingItem(props) {
  return (
    <ListGroup.Item>
      <Nav.Link className={styles.link} href={props.href}>
        <div className={styles.rankCol}>
          {props.rank}
        </div>
        <div className={styles.titleCol}>
          <img
            className={styles.logo}
            src={props.logo}
          />
          {props.title}
        </div>
        <div className={styles.scoreCol}>
          <UqScore score={props.score} />
        </div>
      </Nav.Link>
    </ListGroup.Item>
  );
}
