import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqMarkdownRenderer } from '../markdown';
import { MarkdownRegex, buildHtmlTemplate } from '../../../models';

import * as styles from './typography.module.scss';

const SANITIZER_CONFIG = {
  allowedAttributes: false,
  /** Only supporting basic text by default */
  allowedTags: ['br', 'em', 'span', 'strong'],
}

export const FORMATTER_RULES = [
  { pattern: MarkdownRegex.BOLD, template: buildHtmlTemplate('strong') },
  { pattern: MarkdownRegex.HIGHLIGHT, template: buildHtmlTemplate('span', { className: 'uq-highlight' }) },
  { pattern: MarkdownRegex.ITALIC, template: buildHtmlTemplate('em') },
  { pattern: MarkdownRegex.LINE_BREAK, template: '<br />' },
];

UqTypography.propTypes = {
  as: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,

  /**
   * Typography variant that maps to a typography class.
   * @see src/styles/v2/common/_typography.scss
   */
  variant: PropTypes.string,
};

export function UqTypography(props) {
  const { as: Component = 'span' } = props;

  return (
    <Component className={classNames(styles.root, props.variant, props.className)}>
      <UqMarkdownRenderer
        formatterRules={FORMATTER_RULES}
        sanitizerConfig={SANITIZER_CONFIG}
      >
        {props.children}
      </UqMarkdownRenderer>
    </Component>
  );
}
