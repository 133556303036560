import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useUqContext } from '@uq-hooks';
import { UqAccordionContext, initialState as state } from './context';

import * as styles from './accordion.module.scss';

UqAccordionV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  defaultKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onActivated: PropTypes.func,
};

export function UqAccordionV2(props) {
  const initialState = { ...state, activeKey: props.defaultKey };
  const context = useUqContext(initialState, undefined, { onActivated: props.onActivated });

  return (
    <UqAccordionContext.Provider value={context}>
      <article className={classNames(styles.root, props.className)}>
        {props.children}
      </article>
    </UqAccordionContext.Provider>
  );
}
