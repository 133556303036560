export const SANITIZER_CONFIG = {
  svg: {
    allowedTags: [
      'a',
      'animate',
      'animatemotion',
      'animatetransform',
      'circle',
      'clippath',
      'defs',
      'desc',
      'ellipse',
      'feblend',
      'fecolormatrix',
      'fecomponenttransfer',
      'fecomposite',
      'feconvolvematrix',
      'fediffuselighting',
      'fedisplacementmap',
      'fedistantlight',
      'fedropshadow',
      'feflood',
      'fefunca',
      'fefuncb',
      'fefuncg',
      'fefuncr',
      'fegaussianblur',
      'feimage',
      'femerge',
      'femergenode',
      'femorphology',
      'feoffset',
      'fepointlight',
      'fespecularlighting',
      'fespotlight',
      'fetile',
      'feturbulence',
      'filter',
      'foreignobject',
      'g',
      'image',
      'line',
      'lineargradient',
      'marker',
      'mask',
      'metadata',
      'mpath',
      'path',
      'pattern',
      'polygon',
      'polyline',
      'radialgradient',
      'rect',
      'set',
      'stop',
      'svg',
      'switch',
      'symbol',
      'text',
      'textpath',
      'title',
      'tspan',
      'use',
      'view',
      'vkern',
    ],
    allowedAttributes: false,
  },
};

