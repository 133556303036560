import { useState, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';

export function useSearch(items, keys) {
  const [results, setResults] = useState(null);
  const prev = useRef();

  const search = useCallback((q) => {
    const query = q.trim().toLowerCase();

    const results = query
      ? items.reduce((res, next) => {
        for (const key of keys) {
          const v = getValue(next, key);
          if (v.includes(query)) {
            res.push(next);
          }
        }

        return res;
      }, [])
      : null;

    setResults(results);
  }, [items, setResults]);

  const executeQuery = useCallback((value) => {
    const debounced = debounce(() => search(value), 300);

    prev.current?.debounced?.cancel();
    prev.current = { value, debounced };

    debounced();
  }, []);

  return {
    executeQuery,
    results,
  }
}

function getValue(item, key) {
  return (item[key] || '').toLowerCase();
}
