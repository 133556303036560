import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Figure } from 'react-bootstrap';

import * as styles from './figure-row.module.scss';

UqFigureRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  className: PropTypes.string,
  fit: PropTypes.bool,
  reverse: PropTypes.bool,
};

UqFigureRow.defaultProps = {
  fit: false,
  reverse: false,
};

export function UqFigureRow(props) {
  const [left, right] = props.children;

  return (
    <Figure className={classNames(styles.root, props.className, { [styles.reverse]: props.reverse, [styles.fit]: props.fit })}>
      <Figure.Caption className={classNames(styles.left, styles.block)}>
        {left}
      </Figure.Caption>
      <section className={classNames(styles.right, styles.block)}>
        {right}
      </section>
    </Figure>
  );
}
