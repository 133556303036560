import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Pagination } from 'react-bootstrap';

import * as styles from './pagination.module.scss';

UqPagination.propTypes = {
  active: PropTypes.number,
  onPageSelect: PropTypes.func,
  pages: PropTypes.array,
};

export function UqPagination(props) {
  const pageCount = props.pages.length;

  const half = (pageCount - 1) / 2;
  const lastPage = pageCount - 1;

  return (
    <>
      {pageCount <= 3
        ? (
          <article className={styles.root}>
            <Pagination className="new-uq-theme">
              {props.pages.map((v, index) => v ? renderItem(index) : null)}
            </Pagination>
          </article>
        )
        : (
          <article className={styles.root}>
            <Pagination className="new-uq-theme">
              {props.active > 0 && (
                <Pagination.Item onClick={() => props.onPageSelect(props.active - 1)}>
                  Previous
                </Pagination.Item>
              )}
              {props.active >= half && (
                <>
                  <Pagination.Item onClick={() => props.onPageSelect(0)}>
                    {1}
                  </Pagination.Item>
                  {renderEllipsis()}
                </>
              )}
              {props.pages.map((v, index) => v ? renderItem(index) : null)}
              {props.active < half && (
                <>
                  {renderEllipsis()}
                  <Pagination.Item onClick={() => props.onPageSelect(lastPage)}>
                    {pageCount}
                  </Pagination.Item>
                </>
              )}
              {props.active < lastPage && (
                <Pagination.Item onClick={() => props.onPageSelect(props.active + 1)}>
                  Next
                </Pagination.Item>
              )}
            </Pagination>
          </article>
        )}
    </>
  );

  function renderEllipsis() {
    return (
      <span className={styles.ellipsis}>
        .........
      </span>
    );
  }

  function renderItem(index) {
    const isActive = index === props.active;

    if (!isActive) {
      const isHalf = props.active === half;
      const isFirstHalf = props.active < half;
      const isSecondHalf = props.active > half;

      if (isHalf && (index > props.active + 1 || index < props.active - 1)) {
        return null;
      }

      if (isFirstHalf && (index < props.active || index > props.active + 2)) {
        return null;
      }

      if (isSecondHalf && (index < props.active - 2 || index > props.active)) {
        return null;
      }
    }

    return (
      <Pagination.Item
        key={index}
        className={classNames({ ['active']: props.active === index })}
        onClick={() => props.onPageSelect(index)}
      >
        {index + 1}
      </Pagination.Item>
    );
  }
}
