export const TypeLabelsMap = new Map([
  ['blog', 'Blog'],
  ['e-book', 'eBooks'],
  ['event', 'Events'],
  ['news', 'News'],
  ['podcast', 'Podcasts'],
  ['webinar', 'Webinars'],
]);

export const ActionLabelsMap = new Map([
  ['blog', 'Read Now'],
  ['e-book', 'Read Now'],
  ['event', 'Learn More'],
  ['news', 'Read Now'],
  ['podcast', 'Listen Now'],
  ['webinar', 'Watch Now'],
]);
