export function getRating(score) {
  // 90+ Epic
  if (score >= 90) {
    return 'epic';
  }
  // 70 - 89 Good
  if (score >= 70) {
    return 'good';
  }
  // 51 - 69 Fair
  if (score >= 51) {
    return 'fair';
  }
  // <= 50 Poor
  return 'poor';
}
