import React, { useState, useRef, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';
import { Button } from 'react-bootstrap';

import ChevronIcon from '@uq-assets/chevron-right.svg';
import * as styles from './video-player.module.scss';

export const UqVideoPlayer = forwardRef(
  function VideoPlayer(props, ref) {
    const embedRef = useRef(null);

    const [videoPlayer, setVideoPlayer] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [inactive, setInactive] = useState(props.initialState === 'inactive');

    useImperativeHandle(ref, () => ({
      player: videoPlayer,
      setInactive,
    }));

    const play = useCallback(() => {
      if (props.scrollIntoView) {
        embedRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      }

      props.onPlay();
    }, []);

    useEffect(() => {
      const player = new Player(embedRef.current, props.config);

      if (props.initialState !== 'inactive') {
        player.on('loaded', () => {
          // ensures fade to video is not abrupt
          setTimeout(() => setLoaded(true), 300);
        });
      }

      player.on('ended', () => setInactive(true));

      setVideoPlayer(player);
    }, [props.config, props.initialState]);

    return (
      <>
        <div
          ref={embedRef}
          className={
            classNames(
              styles.root,
              props.classes?.root,
              { [styles.hidden]: !loaded || inactive },
              { [styles.video]: loaded },
              { [styles.animated]: !props.disableAnimation },
            )
          }
        />
        {inactive && (
          <div
            className={classNames(styles.thumbnail, { [styles.animated]: !props.disableAnimation })}
            onClick={async () => {
              setInactive(false);

              await videoPlayer.ready();

              setLoaded(true);
              play();
            }}
          >
            {props.thumbnail}
            <Button
              className={styles.action}
              variant="primary-dark-transparent"
            >
              <div className={styles.playButton}>
                Watch Video
                <ChevronIcon />
              </div>
            </Button>
          </div >
        )}
      </>
    );
  },
);

UqVideoPlayer.propTypes = {
  classes: PropTypes.shape({
    action: PropTypes.string,
    root: PropTypes.string,
  }),
  config: PropTypes.object,
  disableAnimation: PropTypes.bool,
  id: PropTypes.string,
  initialState: PropTypes.oneOf(['active', 'inactive']),
  onPlay: PropTypes.func,
  scrollIntoView: PropTypes.bool,
  thumbnail: PropTypes.element,
};


UqVideoPlayer.defaultProps = {
  disableAnimation: false,
  initialState: 'active',
  onPlay: () => null,
  scrollIntoView: false,
  thumbnail: null,
}
