import { useCallback, useMemo, useRef } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const NAVBAR_HEIGHT = 80;
const NAVBAR_HEIGHT_TABLET = 64;
const NAVBAR_HEIGHT_MOBILE = 56;

export function usePageAnchors(scrollContainerRef) {
  const anchors = useRef(new Map());

  const breakpoint = useBreakpoint();

  const addAnchor = useCallback((id, ref) => {
    anchors.current.set(id, ref);
  }, []);

  const navbarHeight = useMemo(() => {
    if (breakpoint.sm) {
      return NAVBAR_HEIGHT_TABLET;
    }

    if (breakpoint.xs) {
      return NAVBAR_HEIGHT_MOBILE;
    }

    return NAVBAR_HEIGHT;
  }, [breakpoint]);

  const scrollToAnchor = useCallback((id) => {
    const el = anchors.current.get(id);

    const container = scrollContainerRef?.current || window;

    if (container && el) {
      const bounds = el.getBoundingClientRect();

      container.scroll({
        behavior: 'smooth',
        top: bounds.y - navbarHeight,
        left: 0,
      });
    }
  }, [navbarHeight]);

  return {
    addAnchor,
    scrollToAnchor,
  };
}
