// extracted by mini-css-extract-plugin
export var about = "video-testimonial-card-module--about--90ebc";
export var blue = "video-testimonial-card-module--blue--2a4e9";
export var fadeIn = "video-testimonial-card-module--fade-in--8d0a3";
export var footer = "video-testimonial-card-module--footer--626ce";
export var green = "video-testimonial-card-module--green--5187d";
export var header = "video-testimonial-card-module--header--e3412";
export var image = "video-testimonial-card-module--image--efb03";
export var logo = "video-testimonial-card-module--logo--3234f";
export var name = "video-testimonial-card-module--name--cfa20";
export var overlay = "video-testimonial-card-module--overlay--303af";
export var purple = "video-testimonial-card-module--purple--75470";
export var root = "video-testimonial-card-module--root--f0c8d";
export var yellow = "video-testimonial-card-module--yellow--01351";