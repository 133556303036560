import React from 'react';
import PropTypes from 'prop-types';
import { useUqContext } from '@uq-hooks';
import { initialState, UqVideoContext } from './context';

UqVideoV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  config: PropTypes.shape({
    autoplay: PropTypes.bool,
    preview: PropTypes.string,
    /** The video player specific settings to include ie. Vimeo Player */
    settings: PropTypes.shape(),
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export function UqVideoV2(props) {
  const context = useUqContext(initialState, { config: props.config });

  return (
    <UqVideoContext.Provider value={context}>
      {props.children}
    </UqVideoContext.Provider>
  );
}
