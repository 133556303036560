import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useElementById, useEventListener } from '@uq-hooks';
import { UqContainerV2 } from '../layout';
import { DIALOG_CONTAINER_ID } from './dialog-container';

import * as styles from './dialog.module.scss';

export const UqDialog = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);

  const getDialogContainer = useElementById(DIALOG_CONTAINER_ID);

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
  }));

  useEventListener(null, 'mousedown', () => {
    if (!open) {
      return;
    }

    setOpen(false);
  });

  if (!open) {
    return null;
  }

  return createPortal(
    (
      <div className={styles.background}>
        <div
          role="dialog"
          aria-modal="true"
          data-active="true"
          className={classNames('uq-dialog', styles.content)}
        >
          <UqContainerV2>
            {props.children}
          </UqContainerV2>
        </div>
      </div>
    ),
    getDialogContainer(),
  );
});

UqDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

UqDialog.defaultProps = {
  open: false,
};
