import UqAboutIcon from '@uq-assets/nav-icons/company/about.svg';
import UqAgentqIcon from '@uq-assets/nav-icons/product/agentq.svg';
import UqCareersIcon from '@uq-assets/nav-icons/company/careers.svg';
import UqContactIcon from '@uq-assets/nav-icons/company/contact.svg';

import UqIntegrationsIcon from '@uq-assets/nav-icons/product/integrations.svg';
import UqOverviewIcon from '@uq-assets/nav-icons/product/overview.svg';
import UqWhyQualityIcon from '@uq-assets/nav-icons/product/why-quality.svg';

import UqBlogIcon from '@uq-assets/nav-icons/resources/blog.svg';
import UqEventsIcon from '@uq-assets/nav-icons/resources/events.svg';
import UqHelpIcon from '@uq-assets/nav-icons/resources/help-center.svg';
import UqNewsIcon from '@uq-assets/nav-icons/resources/news.svg';
import UqPlusIcon from '@uq-assets/nav-icons/resources/uq-plus.svg';

import UqCXIcon from '@uq-assets/nav-icons/solutions/cx.svg';
import UqEngineeringIcon from '@uq-assets/nav-icons/solutions/engineering.svg';
import UqLeadershipIcon from '@uq-assets/nav-icons/solutions/leadership.svg';
import UqMarketingIcon from '@uq-assets/nav-icons/solutions/marketing.svg';
import UqProductIcon from '@uq-assets/nav-icons/solutions/product.svg';

import UqDatingIcon from '@uq-assets/nav-icons/uq-score/dating.svg';
import UqELearningIcon from '@uq-assets/nav-icons/uq-score/e-learning.svg';
import UqFinanceIcon from '@uq-assets/nav-icons/uq-score/finance.svg';
import UqHealthIcon from '@uq-assets/nav-icons/uq-score/fitness.svg';
import UqFoodIcon from '@uq-assets/nav-icons/uq-score/food.svg';
import UqGamingIcon from '@uq-assets/nav-icons/uq-score/gaming.svg';
import UqMusicIcon from '@uq-assets/nav-icons/uq-score/music.svg';
import UqScoreCardsIcon from '@uq-assets/nav-icons/uq-score/score-cards.svg';
import UqShoppingIcon from '@uq-assets/nav-icons/uq-score/shopping.svg';
import UqTopAppsIcon from '@uq-assets/nav-icons/uq-score/top-apps.svg';
import UqTravelIcon from '@uq-assets/nav-icons/uq-score/travel.svg';
import UqVideoIcon from '@uq-assets/nav-icons/uq-score/video.svg';

export const NAV_ICONS = {
  about: UqAboutIcon,
  agentq: UqAgentqIcon,
  blog: UqBlogIcon,
  careers: UqCareersIcon,
  contact: UqContactIcon,
  cx: UqCXIcon,
  dating: UqDatingIcon,
  elearning: UqELearningIcon,
  engineering: UqEngineeringIcon,
  events: UqEventsIcon,
  finance: UqFinanceIcon,
  food: UqFoodIcon,
  gaming: UqGamingIcon,
  healthandfitness: UqHealthIcon,
  help: UqHelpIcon,
  integrations: UqIntegrationsIcon,
  leadership: UqLeadershipIcon,
  marketing: UqMarketingIcon,
  music: UqMusicIcon,
  news: UqNewsIcon,
  overview: UqOverviewIcon,
  plus: UqPlusIcon,
  product: UqProductIcon,
  scorecards: UqScoreCardsIcon,
  shopping: UqShoppingIcon,
  topapps: UqTopAppsIcon,
  travel: UqTravelIcon,
  video: UqVideoIcon,
  whyquality: UqWhyQualityIcon,
};
