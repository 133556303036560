import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Overlay, Popover } from 'react-bootstrap';

import { useElementById } from '@uq-hooks';

import { UqLink } from '../../link';
import { UqMenu } from '../../../menu';
import { UqNavMenuIcon } from './nav-menu-icon';
import { UqNavMenuItems } from './nav-menu-items';

const UQ_NAVBAR_CONTAINER_ID = 'uq-navbar-container';

import * as styles from './nav-menu-content.module.scss';

UqNavMenuContent.propTypes = {
  cta: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  target: PropTypes.shape({
    current: PropTypes.object,
  }),
  titles: PropTypes.shape(),
  variant: PropTypes.string,
};

export function UqNavMenuContent(props) {
  const getNavbarContainer = useElementById(UQ_NAVBAR_CONTAINER_ID);
  const menuContext = useContext(UqMenu.Context);

  const popperConfig = {
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: getNavbarContainer(),
        },
      },
    ],
  }

  return (
    <Overlay
      placement="bottom-start"
      popperConfig={popperConfig}
      show={menuContext.get('open')}
      target={props.target}
    >
      <Popover className={classNames('new-uq-theme', styles.root)}>
        <Popover.Content className={styles.content}>
          {props.cta && (
            <section className={styles.cta}>
              <h4>{props.cta.title}</h4>
              <p className={classNames('body-1', styles.description)}>
                {props.cta.description}
              </p>
            </section>
          )}

          <UqNavMenuItems
            title={props.titles?.['links']}
            variant={props.variant}
          >
            {props.links.map((v, index) => (
              <UqMenu.Item key={index}>
                <UqLink
                  className={classNames('uq-nav-icon-link', styles.innerLink)}
                  to={v.href}
                  external={v.external}
                  hideArrow
                  variant="inherit"
                >
                  <UqNavMenuIcon>{v.id}</UqNavMenuIcon>
                  <span className={styles.label}>
                    {v.label}
                  </span>
                </UqLink>
              </UqMenu.Item>
            ))}
          </UqNavMenuItems>
          {props.children}
        </Popover.Content>
      </Popover>
    </Overlay>
  );
}
