import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqContainerV2 } from './container';

import * as styles from './float-container.module.scss';

UqFloatContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
}

export function UqFloatContainer(props) {
  return (
    <UqContainerV2 className={styles.root}>
      <div className={classNames(styles.innerContainer, props.className)}>
        {props.children}
      </div>
    </UqContainerV2>
  );
}
