import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './flex-column.module.scss';

UqFlexColumn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function UqFlexColumn(props) {
  return (
    <section className={styles.root}>
      {props.children}
    </section>
  );
}
