import React from 'react';

import Image from '@uq-assets/freya.svg';

import * as styles from './freya.module.scss';

export function Freya() {
  return (
    <div className={styles.root}>
      <Image />
    </div>
  );
}
