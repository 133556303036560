import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { UqScrollContent } from '../scroll-content';

import * as styles from './scroll-anchor.module.scss';

UqScrollAnchor.propTypes = {
  children: PropTypes.node,
  onScroll: PropTypes.func,
};

export function UqScrollAnchor(props) {
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 0,
    });

    props.onScroll?.();
  }, []);

  return (
    <UqScrollContent thresholdPx={500}>
      <div
        className={styles.anchorContainer}
        onClick={scrollToTop}
      >
        <div className={styles.anchor}>
          {props.children}
        </div>
      </div>
    </UqScrollContent>
  );
}
