import { useState, useCallback, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

// @todo [epanulla] Create a context provider which parses the URL params at client side load, rather than server-side,
//  using the Gatsby Browser API https://unitqspace.atlassian.net/browse/EN-19060
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onRouteUpdate
export function useQueryParams(keys, onClientInit) {
  const [params, setParams] = useState({});
  const location = useLocation();

  const updateParameter = useCallback((key, value) => {
    const updated = new URLSearchParams(location.search);

    if (value) {
      updated.set(key, value);
    } else {
      updated.delete(key);
    }

    const uri = `${location.pathname}?${new URLSearchParams(updated).toString()}`;

    navigate(uri, { replace: true });
  }, []);

  useEffect(() => {
    const query = location.search.split('?')[1];

    const urlSearch = new URLSearchParams(query);
    const rawParams = Object.fromEntries(urlSearch.entries());

    const params = keys.reduce((res, key) => {
      res[key] = rawParams[key];
      return res;
    }, {});

    setParams(params);
    onClientInit?.(params);
  }, [location.search]);

  return {
    params,
    updateParameter,
  };
}
