import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ListGroup } from 'react-bootstrap';

import { UqLink } from '../../navigation';
import { UqTooltip } from '../../tooltip';
import { UqRankingItem } from './ranking-item';
import InfoIcon from '../assets/info.svg';

import * as styles from './score-rankings.module.scss';

const DEFAULT_SHOW_COUNT = 10;

UqScoreRankings.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      id: PropTypes.string,
      logo: PropTypes.string,
      score: PropTypes.number,
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};

export function UqScoreRankings(props) {
  const [displayCount, setDisplayCount] = useState(DEFAULT_SHOW_COUNT);

  const items = useMemo(() => props.items.slice(0, displayCount),
    [displayCount, props.items]);

  useEffect(() => {
    // reset back to 10 items when switching between categories
    setDisplayCount(DEFAULT_SHOW_COUNT);
  }, [props.items]);

  return (
    <section className={styles.root}>
      <div className={styles.wrapper}>
        <header className={styles.headerRow}>
          <div className={styles.rankCol}>
            Rank
          </div>
          <div className={styles.titleCol}>
            Product
          </div>
          <div className={styles.scoreCol}>
            <span>unitq Score </span>
            {renderInfoTooltip()}
          </div>
        </header>
        <ListGroup className="card-list">
          {items.map((item, index) => (
            <UqRankingItem
              key={index}
              href={`/unitq-scorecards/${item.slug}`}
              logo={item.logo}
              rank={index + 1}
              score={item.score}
              title={item.title}
            />
          ))}
        </ListGroup>
        {props.items.length > displayCount && (
          <div className={styles.loadMore}>
            <Button
              className="body-2"
              onClick={() => setDisplayCount(props.items.length)}
              variant="link"
            >
              Load More
            </Button>
          </div>
        )}

        <div className={styles.linkContainer}>
          <UqLink
            className={styles.link}
            to="/unitq-scorecards/"
          >
            See All Companies
          </UqLink>
        </div>
      </div>
    </section>
  );

  function renderInfoTooltip() {
    return (
      <UqTooltip
        content={(
          <div className={styles.tooltip}>
            The unitQ Score measures the quality of the user experience based on customer feedback.
            In this case, the score is based on Google Play Store and Apple App Store reviews.
          </div>
        )}
      >
        <span className={styles.info}>
          <InfoIcon />
        </span>
      </UqTooltip>
    );
  }
}
