import PropTypes from 'prop-types';

export const actionShape = PropTypes.shape({
  href: PropTypes.string,
  label: PropTypes.string,
  metadata: PropTypes.shape({
    variant: PropTypes.string,
  }),
  type: PropTypes.oneOf(['link', 'button']),
});
