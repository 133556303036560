// extracted by mini-css-extract-plugin
export var headerRow = "score-rankings-module--header-row--195ef";
export var info = "score-rankings-module--info--855f3";
export var link = "score-rankings-module--link--5895d";
export var linkContainer = "score-rankings-module--link-container--cb96b";
export var loadMore = "score-rankings-module--load-more--bbe56";
export var rankCol = "score-rankings-module--rank-col--f8bb8";
export var root = "score-rankings-module--root--68b59";
export var scoreCol = "score-rankings-module--score-col--15111";
export var titleCol = "score-rankings-module--title-col--5ef3e";
export var tooltip = "score-rankings-module--tooltip--145eb";
export var wrapper = "score-rankings-module--wrapper--0aec8";