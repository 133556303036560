import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';

import { UqLogo } from '../../../unitq-logo';
import { UqContainerV2 } from '../../../layout/container/v2';
import { UqLegacyRequestDemoButton } from '../../../request-demo';
import { UqLink } from '../../link';

import * as styles from './desktop-nav-bar.module.scss';

UqDesktopNavbarV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export function UqDesktopNavbarV2(props) {
  return (
    <nav className={styles.root}>
      <UqContainerV2
        id="uq-navbar-container"
        className={styles.navContainer}
      >
        <UqLink
          to="/"
          hideArrow
        >
          <UqLogo className={styles.logo} />
        </UqLink>
        <ul className={styles.links}>
          {props.children}
        </ul>
        <section className={styles.actions}>
          <UqLink
            as={Button}
            className={styles.navAction}
            to="https://monitor.unitq.com/login"
            metadata={{ variant: 'outline-secondary' }}
            variant="inherit"
            external
          >
            Login
          </UqLink>
          <UqLegacyRequestDemoButton className={classNames(styles.requestDemoButton, 'wide')} />
        </section>
      </UqContainerV2>
    </nav>
  );
}
