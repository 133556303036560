import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav } from 'react-bootstrap';

import { NAV_ICONS } from './content';

import * as styles from './nav-icon-link.module.scss';

UqNavIconLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
  }),
  href: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.string,
};

export function UqNavIconLink(props) {
  const Icon = NAV_ICONS[props.icon];

  const linkClasses = classNames(props.classes?.root, styles.link, 'body-1-strong');
  const iconClasses = classNames(props.classes?.icon, styles[props.icon]);

  const target = props.target;

  if (!Icon) {
    return null;
  }

  return (
    <Nav.Link
      className={linkClasses}
      href={props.href}
      target={target}
      rel={target ? target : undefined}
      onClick={props.onClick}
    >
      <Icon className={iconClasses} />
      {props.children}
    </Nav.Link>
  );
}
