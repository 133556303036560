import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqMenu } from './menu';

import * as styles from './menu-transition.module.scss';

MenuTransition.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export function MenuTransition(props) {
  const menuContext = useContext(UqMenu.Context);

  return (
    <div
      className={classNames(styles.root, props.className, {
        [styles.active]: menuContext.get('open'),
      })}
    >
      {props.children}
    </div>
  );
}
