import { useMemo, useState } from 'react';
import chunk from 'lodash/chunk';

export function usePagination(dataSet, pageSize) {
  const [activePageNumber, setActivePageNumber] = useState(0);

  const pages = useMemo(() => {
    return chunk(dataSet, pageSize);
  }, [dataSet, pageSize]);

  const goTo = (pageNumber) => setActivePageNumber(pageNumber);

  return {
    activePageNumber,
    currentPage: pages[activePageNumber],
    goTo,
    pages,
  };
}
