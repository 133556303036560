import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';

import { Content } from './content';
import * as styles from './nav-social.module.scss';

UqNavSocial.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string,
  }),
};

export function UqNavSocial(props) {
  return (
    <article className={styles.root}>
      {Content.social.map((v) => {
        const Icon = v.icon;

        return (
          <Nav.Link
            key={v.id}
            className={styles.link}
            href={v.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon className={props.classes?.icon} />
          </Nav.Link>
        );
      })}
    </article>
  );
}
