import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './animation-interval.module.scss';

UqAnimationInterval.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  classes: PropTypes.shape({
    active: PropTypes.string,
    inactive: PropTypes.string,
    root: PropTypes.string,
  }),
  delay: PropTypes.number,
  interval: PropTypes.number,
};

/**
 * Animates the child elements on an interval.
 * Children are animated via the active, and inactive classes provided.
 */
export function UqAnimationInterval(props) {
  const [delay, setDelay] = useState(props.delay || null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [isMounted, setMounted] = useState(false);

  const items = useMemo(() => {
    return React.Children.map(
      props.children,
      (el, index) => (
        <AnimatedItem
          className={classNames({
            [props.classes.active]: index === activeIndex && !delay,
            [props.classes.inactive]: index !== activeIndex && !(delay && !index),
            [styles.hidden]: index && index !== activeIndex && delay,
          })}
        >
          {React.cloneElement(el)}
        </AnimatedItem>
      ),
    );
  }, [props.children, activeIndex, delay]);

  const animate = useCallback(() => {
    setTimeout(() => {
      if (activeIndex === items.length - 1) {
        setActiveIndex(0);
      } else {
        setActiveIndex((v) => v + 1);
      }

      if (delay) {
        setDelay(null);
      }
    }, delay || 0);
  }, [items, delay, setDelay, activeIndex, setActiveIndex]);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    if (intervalId) {
      clearInterval(intervalId);
    }

    setIntervalId(setInterval(animate, props.interval));

    return () => {
      clearInterval(intervalId);
      setMounted(false);
    };
  }, [animate]);

  return (
    <div className={classNames(props.classes?.root, styles.stage)}>
      {items}
    </div>
  );
}

AnimatedItem.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
};

function AnimatedItem(props) {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  );
}
