import PropTypes from 'prop-types';

export const tileShape = PropTypes.shape({
  description: PropTypes.string,
  desc: PropTypes.shape({
    desc: PropTypes.string,
  }),
  title: PropTypes.string,
  icon: PropTypes.shape({
    svg: PropTypes.shape({
      content: PropTypes.string,
    }),
  }),
});
