import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';

import * as styles from './score-card-list-anchors.module.scss';

const ANCHOR_KEYS = 'ABCDEFGHIJKLMNOPQRQRSTUVWXYZ#'.split('');

UqScoreCardListAnchors.propTypes = {
  onSelect: PropTypes.func,
}

export function UqScoreCardListAnchors(props) {
  return (
    <section className={styles.root}>
      <ul className={styles.filterList}>
        {ANCHOR_KEYS.map((key, index) => {
          return (
            <li
              key={index}
              className={styles.item}
            >
              <Button
                className={styles.filter}
                variant="link"
                onClick={() => props.onSelect(key)}
              >
                {key}
              </Button>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
