import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useUqContext } from '@uq-hooks';

import { UqLogo } from '../../../unitq-logo';
import { UqMenu } from '../../../menu';
import { UqAppBarV2, UqLayoutContext, UqContainerV2 } from '../../../layout';
import { UqLink } from '../../link';
import { UqNavToggle } from './nav-toggle';
import { UqMobileNavOverlay } from './mobile-nav-overlay';
import { UqMobileNavContext, initialState } from './context';

import * as styles from './mobile-nav.module.scss';

UqMobileNavV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export function UqMobileNavV2(props) {
  const layoutContext = useContext(UqLayoutContext);
  const appbarContext = useContext(UqAppBarV2.Context);
  const context = useUqContext(initialState);

  const activateMenu = useCallback(() => {
    appbarContext.update('active', true)
  }, [appbarContext]);

  const deactivateMenu = useCallback(() => {
    context.update('activeMenu', null);
    appbarContext.update('active', false);
  }, [context, appbarContext]);

  const appbarTheme = layoutContext.get('theme');
  const isAppbarActive = appbarContext.get('active');
  const isAppbarHovered = appbarContext.get('isHovered');
  const isScrolled = appbarContext.get('isScrolled');

  const isDarkTheme = appbarTheme === 'dark' && !(isScrolled || isAppbarHovered || isAppbarActive);

  return (
    <UqMobileNavContext.Provider value={context}>
      <nav className={styles.root}>
        <UqContainerV2 className={styles.navContainer}>
          <UqLink
            to="/"
            hideArrow
          >
            <UqLogo className={styles.logo} />
          </UqLink>
          <UqMenu
            onOpen={activateMenu}
            onClose={deactivateMenu}
          >
            <UqMenu.Toggle
              as={UqNavToggle}
              theme={isDarkTheme ? 'dark' : 'light'}
            />
            <UqMobileNavOverlay>
              {props.children}
            </UqMobileNavOverlay>
          </UqMenu>
        </UqContainerV2>
      </nav>
    </UqMobileNavContext.Provider >
  );
}
