import React from 'react';

import * as styles from './avatar-image.module.scss';

export function AvatarImage(props) {
  return (
    <div className={styles.root}>
      {props.children}
    </div>
  );
}
