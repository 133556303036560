import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Image } from 'react-bootstrap';

import { testimonialShape } from '../../../../models';
import { UqSvg } from '../../media';

import * as styles from './video-testimonial-card.module.scss';

export const CARD_COLORS = ['purple', 'yellow', 'blue', 'green'];

UqVideoTestimonialCard.propTypes = {
  color: PropTypes.oneOf(CARD_COLORS),
  hideDetails: PropTypes.bool,
  testimonial: testimonialShape,
};

UqVideoTestimonialCard.defaultProps = {
  hideDetails: false,
};

export function UqVideoTestimonialCard(props) {
  const author = props.testimonial.author;
  const customer = props.testimonial.customer;

  return (
    <Card className={styles.root}>
      <div className={classNames(styles.overlay, styles[props.color])}
      />
      <section className={styles.header}>
        <UqSvg className={styles.logo}>
          {customer.spotlightLogo.svg.content}
        </UqSvg>
      </section>
      {!props.hideDetails && (
        <section className={styles.footer}>
          <div className={styles.about}>
            <span className={styles.name}>
              {author.name}
            </span>
            <span>{author.role}</span>
          </div>
        </section>
      )}
      <Image
        className={styles.image}
        src={author.thumbnail.url}
      />
    </Card>
  );
}
