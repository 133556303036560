import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';

import { UqTypography } from '../../typography';
import { UqQuoteCard } from '../quote-card';
import { UqQuoteCarouselControl } from './quote-carousel-control';

import * as styles from './quote-carousel.module.scss';

UqQuoteCarousel.propTypes = {
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.shape({
        avatar: PropTypes.elementType,
        name: PropTypes.string.isRequired,
        role: PropTypes.string,
      }),
      logo: PropTypes.elementType,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string,
};

export function UqQuoteCarousel(props) {
  return (
    <section className={styles.container}>
      <div className={styles.quoteTitle}>
        <UqTypography as="h2">
          Loved by !-category leaders-!
        </UqTypography>
      </div>
      <Carousel
        aria-controls="quote-carousel-controls"
        nextIcon={renderControl('right')}
        prevIcon={renderControl('left')}
        controls>
        {props.quotes.map((quote, index) => {
          return (
            <Carousel.Item key={index}>
              <div className={styles.itemWrapper}>
                <UqQuoteCard
                  author={quote.author}
                  color={quote.color}
                  logo={quote.logo}
                >
                  {quote.text}
                </UqQuoteCard>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </section>
  );

  function renderControl(dir) {
    return <UqQuoteCarouselControl direction={dir} />
  }
}
