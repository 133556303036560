import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';

import { useHover } from '@uq-hooks';
import { LinkArrow } from './arrow';

import * as styles from './link.module.scss';

LinkV2.propTypes = {
  active: PropTypes.bool,
  as: PropTypes.any,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  external: PropTypes.bool,
  hideArrow: PropTypes.bool,
  metadata: PropTypes.object,
  onClick: PropTypes.func,
  to: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'inherit']),
};

LinkV2.defaultProps = {
  external: false,
  hideArrow: false,
};

export function LinkV2(props) {
  const { isHovered, hoverTriggers } = useHover();

  const isHoverActive = props.active != null ? props.active : isHovered;

  const LinkComponent = getLinkComponent();
  const linkProps = props.onClick ? {} : getLinkProps(LinkComponent);

  return (
    <LinkComponent
      className={
        classNames(
          styles.root,
          props.className,
          { [styles.inheritColor]: props.variant === 'inherit' },
        )
      }
      {...linkProps}
      onClick={props.onClick}
    >
      <span
        className={classNames('uq-link-container', styles.linkContainer)}
        {...hoverTriggers}
      >
        {props.children}
        {!props.hideArrow && renderArrow(LinkComponent)}
      </span>
    </LinkComponent>
  );

  function getLinkComponent() {
    const Component = props.as;

    if (props.as) {
      return Component;
    }

    if (props.external || props.onClick) {
      return 'a';
    }

    return GatsbyLink;
  }

  function getLinkProps(reactElement) {
    let linkProps = {};

    if (props.external || isButton(reactElement)) {
      linkProps.href = props.to;
    } else {
      linkProps.to = props.to;
    }

    if (props.external) {
      linkProps.rel = 'noopener noreferrer';
      linkProps.target = '_blank';
    }

    if (props.metadata) {
      linkProps = { ...linkProps, ...props.metadata };
    }

    return linkProps;
  }

  function renderArrow(reactElement) {
    if (isButton(reactElement)) {
      return null;
    }

    return (
      <LinkArrow active={isHoverActive} />
    );
  }
}

/** Checks to see if the element is a Button */
function isButton(element) {
  return element.displayName === 'Button';
}
