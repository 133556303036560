import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { actionShape } from '../../../models';
import { UqLink } from '../navigation';

import * as styles from './figure-actions.module.scss';

UqFigureActions.propTypes = {
  actions: PropTypes.arrayOf(actionShape),
  variant: PropTypes.oneOf(['default', 'light']),
};

export function UqFigureActions(props) {
  return (
    <section className={styles.root}>
      {props.actions?.map((action, index) => {
        const overrides = getActionOverrides(action, index);

        return (
          <React.Fragment key={index}>
            <UqLink
              as={overrides?.Component}
              metadata={overrides?.metadata}
              to={action.href}
            >
              {action.label}
            </UqLink>
          </React.Fragment>
        );
      })}
    </section>
  );

  function getActionOverrides(action, index) {
    if (action.type === 'button') {
      const outlineVariant = props.variant === 'light' ? 'outline-light' : 'outline-secondary';

      return {
        Component: Button,
        metadata: {
          variant: index !== 0 ? outlineVariant : 'primary',
        },
      };
    }

    return null;
  }
}
