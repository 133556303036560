/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/style.scss';

export { wrapRootElement } from './gatsby-helper';

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }))
  }
}

const WILDCARD_PATH = '/*';

/**
 * Ignore default scroll by page
 * This is used for cases where the page should always start at the top on load,
 * or manual manipulation of the scroll is needed (ie. filters, search, etc)
 */
const IGNORE_UPDATE_SCROLL_CONFIGS = [
  { path: '/blog/', enabled: false },
  { path: '/blog/*', enabled: true },
  { path: '/customers', enabled: false },
  { path: '/customers/*', enabled: true },
  { path: '/insiders-look-/*', enabled: true },
  { path: '/integrations', enabled: true },
];

/** Return true if Gatsby should keep track of the scroll position */
/** @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#shouldUpdateScroll  */
export const shouldUpdateScroll = ({ routerProps }) => {
  for (const config of IGNORE_UPDATE_SCROLL_CONFIGS) {
    if (routerProps.location.pathname === config.path) {
      return config.enabled;
    }

    const isWildcard = config.path.endsWith(WILDCARD_PATH);
    const normalizedPath = normalizeBasePath(config.path);

    if (isWildcard && routerProps.location.pathname.includes(normalizedPath)) {
      return config.enabled;
    }
  }

  return true;
};

function normalizeBasePath(path) {
  return path.replace(WILDCARD_PATH, '').trim();
}
