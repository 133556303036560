import PropTypes from 'prop-types';

import { actionShape } from './action';

export const figureRowShape = PropTypes.shape({
  actions: PropTypes.arrayOf(actionShape),
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  /** Uses boolean to toggle left/right on Contentful */
  textAlign: PropTypes.bool,
  title: PropTypes.string.isRequired,
});
