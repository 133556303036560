import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { useHover } from '../../../hooks/v2';
import { UqLink } from '../navigation';

import * as styles from './explore-tile.module.scss';

ExploreTile.propTypes = {
  variant: PropTypes.oneOf(['default', 'condensed']),
};

ExploreTile.defaultProps = {
  variant: 'default',
};

export function ExploreTile(props) {
  const isCondensed = props.variant === 'condensed';

  const { isHovered, hoverTriggers } = useHover();

  return (
    <Link
      className={classNames(
        styles.root,
        { [styles.condensed]: isCondensed },
      )}
      to="/customers/"
      {...hoverTriggers}
    >
      <h3 className={styles.title}>
        {'Explore \n Customer Stories'}
      </h3>
      <span className={styles.arrow}>
        <UqLink.Arrow
          active={isHovered}
          size={isCondensed ? 'default' : 'lg'}
          variant="dark"
        />
      </span>
    </Link>
  );
}
