import React from 'react';
import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import { UqSvg } from '../media';

import * as styles from './customer-logos.module.scss';

const query = graphql`
  query {
    customers: contentfulContentGroup(slug: {eq: "customer-logos"}) {
      content {
        ... on ContentfulCustomer {
          logo {
            svg {
              content: originalContent
            }
          }
        }
      }
    }
  }
`

export function UqCustomerLogos() {
  const { customers } = useStaticQuery(query);

  return (
    <div className={styles.logosListFrame}>
      <Container className="new-uq-theme">
        <p className={classNames('body-2', styles.logosListPromoText)}>
          Trusted by
        </p>
        <ul className={styles.logosList}>
          {customers.content.map((customer, index) => (
            <li key={index}>
              <UqSvg className={styles.logosListImage}>
                {customer.logo.svg.content}
              </UqSvg>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
}
