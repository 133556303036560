import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqLink } from '../link';

import * as styles from './footer-nav-bar-item.module.scss';

FooterNavbarItem.propTypes = {
  children: PropTypes.node,
  item: PropTypes.arrayOf(
    PropTypes.shape({
      external: PropTypes.bool,
      href: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          to: PropTypes.string,
        }),
      ),
    }),
  ),
};

export function FooterNavbarItem(props) {
  return (
    <li className={styles.root}>
      {props.item.map((v, index) => {
        return (
          <React.Fragment key={index}>
            {!v.href
              ? (
                <span className={classNames('body-2', styles.parent)}>
                  {v.label}
                </span>
              )
              : (
                <UqLink
                  className={classNames('body-2', styles.parent)}
                  to={v.href}
                  external={v.external}
                  variant="inherit"
                  hideArrow
                >
                  {v.label}
                </UqLink>
              )
            }
            {v.links && renderLinkGroup(v.links)}
          </React.Fragment>
        );
      })}
    </li >
  );

  function renderLinkGroup(links) {
    return (
      <ul className={styles.group}>
        {links.map((v, index) => (
          <li
            key={index}
            className={styles.secondary}
          >
            <UqLink
              className="body-2"
              to={v.href}
              external={v.external}
              variant="inherit"
              hideArrow
            >
              {v.label}
            </UqLink>
          </li>
        ))}
      </ul>
    );
  }
}
