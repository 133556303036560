import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Image } from 'react-bootstrap';

import { figureRowShape } from '../../../../models';
import { UqFlexRow } from '../../layout';
import { UqSvg } from '../../media';
import { UqLink } from '../../navigation';

import * as styles from './customer-spotlight.module.scss';

UqCustomerSpotlight.propTypes = {
  customer: PropTypes.shape({
    logo: PropTypes.shape({
      svg: PropTypes.shape({
        content: PropTypes.string,
      }),
    }),
  }),
  figure: figureRowShape,
};

export function UqCustomerSpotlight(props) {
  return (
    <UqFlexRow>
      <Col xl={6} lg={6} md={12}>
        <div className={styles.splashContainer}>
          <Image
            className={styles.splash}
            src={props.figure.image.url}
          />
        </div>
      </Col>
      <Col xl={6} lg={6} md={12}>
        <div className={styles.textCol}>
          <UqSvg className={styles.logo}>
            {props.customer.logo.svg.content}
          </UqSvg>
          <h2 className={styles.title}>
            {props.figure.title}
          </h2>
          <div className={classNames('body-3', styles.description)}>
            {props.figure.description.description}
          </div>
          {props.figure.actions.map((action, index) => (
            <UqLink
              key={index}
              to={action.href}
            >
              {action.label}
            </UqLink>
          ))}
        </div>
      </Col>
    </UqFlexRow>
  );
}
