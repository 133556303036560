import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './menu-item.module.scss'

MenuItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export function MenuItem(props) {
  return (
    <li
      role="menuitem"
      className={classNames(styles.root, props.className)}
    >
      {props.children}
    </li>
  );
}
