import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useUqContext } from '@uq-hooks';
import { initialState, UqLayoutContext } from './context';

import * as styles from './layout.module.scss';

UqLayoutV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  theme: PropTypes.oneOf(['light', 'dark']),
};

export function UqLayoutV2(props) {
  const context = useUqContext(initialState, {
    theme: props.theme,
  });

  return (
    <UqLayoutContext.Provider value={context}>
      <main
        className={classNames(styles.root, {
          [styles.dark]: props.theme === 'dark',
        })}
      >
        {props.children}
      </main>
    </UqLayoutContext.Provider>
  );
}
