import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './unitq-logo-background.module.scss';

UqLogoBackground.propTypes = {
  anchor: PropTypes.oneOf([
    'bottomLeft',
    'bottomRight',
    'topLeft',
    'topRight',
  ]),
  className: PropTypes.string,
  children: PropTypes.element,
  dark: PropTypes.bool,
};

UqLogoBackground.defaultProps = {
  dark: false,
};

export function UqLogoBackground(props) {
  return (
    <div
      className={classNames(
        styles.root,
        props.className,
        {
          [styles[props.anchor]]: true,
          [styles.dark]: props.dark,
          [styles.primary]: !props.dark,
        })}
    >
      {props.children}
    </div>
  );
}
