import React from 'react';
import PropTypes from 'prop-types';
import { UqHtmlSanitizer } from './html-sanitizer';

UqMarkdownRenderer.propTypes = {
  children: PropTypes.string,
  formatterRules: PropTypes.arrayOf(
    PropTypes.shape({
      pattern: PropTypes.any,
      template: PropTypes.string,
    }),
  ),
  sanitizerConfig: PropTypes.shape({
    allowedAttributes: PropTypes.bool,
    allowedTags: PropTypes.arrayOf(PropTypes.string),
  }),
};

/**
 * Renders supported markdown from a text string
 * e.g. From a source such as Contentful
 */
export function UqMarkdownRenderer(props) {
  return (
    <UqHtmlSanitizer config={props.sanitizerConfig}>
      {formatMarkdown(props.children, props.formatterRules)}
    </UqHtmlSanitizer>
  );
}

function formatMarkdown(value, formatterRules) {
  let result = value;

  for (const { pattern, template } of formatterRules) {
    result = result.replace(pattern, template);
  }

  return result;
}
