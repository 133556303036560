import throttle from 'lodash/throttle';
import * as styles from './carousel.module.scss';

export function animate(element, { className, duration, onComplete }) {
  element.classList.add(className);

  setTimeout(() => {
    element.classList.remove(className);

    onComplete?.();
  }, duration);
}

export const throttledBack = throttle((displayItems, setDisplayItems, stageRef) => {
  let updatedItems = [...displayItems];

  updatedItems.unshift(updatedItems.pop());
  setDisplayItems(updatedItems);

  animate(stageRef.current, {
    className: styles.slidePrev,
    duration: 1000,
  });
}, 2000, { trailing: false });

export const throttledNext = throttle((displayItems, setDisplayItems, stageRef) => {
  animate(stageRef.current, {
    className: styles.slideNext,
    duration: 1000,
    onComplete: () => {
      let updatedItems = [...displayItems];

      updatedItems.push(updatedItems.shift());
      setDisplayItems(updatedItems);
    },
  });
}, 2000, { trailing: false });
