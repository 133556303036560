import { UqVideoV2 } from './video';
import { VideoPlayer } from './video-player';
import { VideoTrigger } from './video-trigger';

export { UqVideoManager } from './video-manager';
export { UqVideoManagerContext } from './video-manager-context';

UqVideoV2.Player = VideoPlayer;
UqVideoV2.Trigger = VideoTrigger;

export { UqVideoV2 };
