import PropTypes from 'prop-types';

export const svgShape = PropTypes.shape({
  svg: PropTypes.shape({
    content: PropTypes.string,
  }),
});

export const ImageShape = PropTypes.shape({
  url: PropTypes.string.isRequired,
});
