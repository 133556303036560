export const Content = {
  solutions: [
    {
      ctaText: 'Resolve Issues Quickly',
      href: '/solutions/engineering/',
      icon: 'engineering',
      title: 'Engineering',
      subtitle: 'Detect urgent issues sooner to improve time to fix.',
    },
    {
      ctaText: 'Drive Growth',
      href: '/solutions/product-operations/',
      icon: 'product',
      title: 'Product',
      subtitle: 'Identify top requested features to build best-in-class products',
    },
    {
      ctaText: 'Build Loyalty',
      href: '/solutions/support/',
      icon: 'cx',
      title: 'CX',
      subtitle: 'Focus on fixing high priority user issues to reduce support requests.',
    },
    {
      ctaText: 'Increase Awareness',
      href: '/solutions/marketing/',
      icon: 'marketing',
      title: 'Marketing',
      subtitle: 'Supercharge product launches and campaigns with user insights.',
    },
    {
      ctaText: 'Transform Your Business',
      href: '/solutions/leadership/',
      icon: 'leadership',
      title: 'Leadership',
      subtitle: 'Make data-driven decisions based on user trends to drive growth.',
    },
  ],
};
