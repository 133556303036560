/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

function SEO({
  description, lang, meta, keywords, title, image, appendHostUrl,
}) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
            defaultTitle: title
            defaultDescription: description
            defaultImage: image
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: metaDescription || defaultDescription,
    image: appendHostUrl ? `${siteUrl}${image || defaultImage}` : (image || defaultImage),
    url: `${siteUrl}${pathname}`,
  };
  useEffect(() => {
    if (window.addGclid) {
      window.addGclid();
    }
  });
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      defer={false}
      titleTemplate={title || `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:url',
          content: seo.url,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: seo.image,
        },
        {
          property: 'og:image:secure_url',
          content: seo.image,
        },
        {
          property: 'og:image:secure',
          content: seo.image,
        },
        {
          name: 'twitter:image',
          content: seo.image,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
            : [],
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  appendHostUrl: true,
  lang: 'en',
  meta: [],
  keywords: [],
  description: '',
  image: '/images/unitqog.png',
};

SEO.propTypes = {
  appendHostUrl: PropTypes.bool,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default SEO;
