import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './quote-author.module.scss';

UqQuoteAuthor.propTypes = {
  avatar: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.string,
  ]),
  name: PropTypes.string.isRequired,
  role: PropTypes.string,
};

export function UqQuoteAuthor(props) {
  const nameClasses = classNames('body-2-strong', styles.name);
  const roleClasses = classNames('body-2', styles.role);

  return (
    <div className={styles.root}>
      {renderImage()}
      <div className={styles.nameContainer}>
        <p className={nameClasses}>{props.name}</p>
        <p className={roleClasses}>{props.role}</p>
      </div>
    </div>
  );

  function renderImage() {
    const isSrc = typeof props.avatar === 'string';
    const Component = props.avatar;

    return isSrc
      ? (
        <img
          className={styles.avatar}
          src={props.avatar}
        />
      )
      : <Component />
  }
}
