import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Nav, Image } from 'react-bootstrap';

import OpenNewIcon from '../../../content/v2/assets/open-new.svg';

import * as styles from './portrait.module.scss';

UqPortrait.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  role: PropTypes.string,
};

export function UqPortrait(props) {
  return (
    <Nav.Link
      className={styles.root}
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <section className={styles.imageContainer}>
        <Image
          className={styles.image}
          src={props.src}
        />
        <div className={styles.overlay}>
          <OpenNewIcon className={styles.openNewIcon} />
        </div>
      </section>
      <div className={styles.details}>
        <h4>{props.name}</h4>
        <span className={classNames(styles.role, 'body-2')}>
          {props.role}
        </span>
      </div>
    </Nav.Link>
  );
}
