import React from 'react';

import { UqLegacyRequestDemoButton } from '../request-demo';
import { UqTextHighlight } from '../typography';

import * as styles from './gpt-cta.module.scss';

export function UqGptCta() {
  return (
    <article className={styles.root}>
      <section className={styles.title}>
        <UqTextHighlight
          as="h4"
          renderHighlight={(value) => (
            <>
              <br />
              {value}
            </>
          )}
        >
          Want to know what your users want? **Just ask!**
        </UqTextHighlight>
      </section>
      <section className={styles.action}>
        <UqLegacyRequestDemoButton
          className="wide"
          buttonProps={{ variant: 'outline-modern' }}
        />
      </section>
    </article>
  );
}
