import React from 'react';
import PropTypes from 'prop-types';

import { OverlayTrigger } from 'react-bootstrap';

import * as styles from './tooltip.module.scss';

UqTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export function UqTooltip(props) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <div className={styles.root}>
          {props.content}
        </div>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
}
