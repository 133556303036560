import PropTypes from 'prop-types';
import { svgShape } from './media';

export const testimonialShape = PropTypes.shape({
  author: PropTypes.shape({
    company: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
    }),
    name: PropTypes.string,
    role: PropTypes.string,
    thumbnail: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  customer: PropTypes.shape({
    logo: svgShape,
    spotLightLogo: svgShape,
  }),
  link: PropTypes.string,
  quote: PropTypes.shape({
    quote: PropTypes.string,
  }),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  videoUrl: PropTypes.string,
});

