import React from 'react';
import PropTypes from 'prop-types';

import { useUqContext } from '@uq-hooks';
import { initialState, UqVideoManagerContext } from './video-manager-context';

UqVideoManager.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export function UqVideoManager(props) {
  const context = useUqContext(initialState);

  return (
    <UqVideoManagerContext.Provider value={context}>
      {props.children}
    </UqVideoManagerContext.Provider>
  );
}
