/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Card, Image } from 'react-bootstrap';

import { useHover } from '../../../hooks/v2';
import { actionShape, svgShape } from '../../../models';
import { UqLink } from '../navigation';
import { UqSvg } from '../media';

import * as styles from './testimonial-card.module.scss';

UqTestimonialCard.propTypes = {
  action: actionShape,
  avatarSrc: PropTypes.string,
  logo: svgShape,
  name: PropTypes.string,
  quote: PropTypes.string,
  role: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'flipable']),
};

export function UqTestimonialCard(props) {
  const { isHovered, hoverTriggers } = useHover();

  const debouncedEnter = debounce(hoverTriggers.onMouseEnter, 150);
  const debouncedLeave = debounce(hoverTriggers.onMouseLeave, 150);

  const isFlipable = props.variant === 'flipable';

  return (
    <div
      onMouseEnter={debouncedEnter}
      onMouseLeave={debouncedLeave}
      className={
        classNames(
          styles.root,
          { [styles.flipable]: isFlipable },
          { [styles.hovered]: isHovered },
        )
      }
    >
      <div className={classNames(styles.card, { [styles.flipable]: isFlipable })}>
        <Card className={classNames(styles.front, { [styles.flipable]: isFlipable })}>
          <Card.Title className={styles.title}>
            <UqSvg className={styles.logo}>
              {props.logo.svg.content}
            </UqSvg>
          </Card.Title>
          <Card.Body className={classNames(styles.body, { [styles.flipable]: isFlipable })}>
            {isFlipable && renderAbout()}
            <section className={classNames(styles.quote, { [styles.flipable]: isFlipable })}>
              <blockquote className={classNames({ 'body-2': isFlipable })}>
                <p>{props.quote}</p>
              </blockquote>
              {isFlipable && (
                <UqLink
                  className={styles.action}
                  to={props.action.href}
                >
                  {props.action.label}
                </UqLink>
              )}
            </section>
            {!isFlipable && (
              <section className={classNames(styles.content, { [styles.flipable]: isFlipable })}>
                {renderAvatar()}
                {renderAbout()}
              </section>
            )}
          </Card.Body>
        </Card>
        {isFlipable && renderBack()}
      </div>
    </div>
  );

  function renderAbout() {
    return (
      <section className={classNames(styles.about, { [styles.flipable]: isFlipable })}>
        <div className={classNames(styles.name, { [styles.flipable]: isFlipable })}>
          {props.name}
        </div>
        <div className={styles.role}>
          {props.role}
        </div>
      </section>
    );
  }

  function renderAvatar() {
    return (
      <Image
        className={classNames(styles.avatar, { [styles.flipable]: isFlipable })}
        src={props.avatarSrc}
      />
    );
  }

  function renderBack() {
    return (
      <Card className={classNames(styles.back, { [styles.flipable]: isFlipable })}>
        <Card.Title className={styles.title}>
          <UqSvg className={styles.logo}>
            {props.logo.svg.content}
          </UqSvg>
        </Card.Title>
        <Card.Body className={classNames(styles.body, { [styles.flipable]: isFlipable })}>
          {renderAvatar()}
          {renderAbout()}
        </Card.Body>
      </Card>
    )
  }
}
