import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './cookie-link.module.scss';

UqCookieLink.propTypes = {
  className: PropTypes.string,
};

export function UqCookieLink(props) {
  return (
    <div
      id={classNames('ot-sdk-btn', styles.otSdkBtn)}
      className={classNames('caption-2', 'ot-sdk-show-settings', styles.otSdkShowSettings)}
    >
      <div className={classNames(props.className, styles.uqLink)}>
        Cookies
      </div>
    </div>
  );
}
