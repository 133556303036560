import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Row } from 'react-bootstrap';

import * as styles from './flex-row.module.scss';

UqFlexRow.propTypes = {
  as: PropTypes.elementType,
  /**
   * Specifies the last breakpoint where the row should display horizontally
   */
  breakpoint: PropTypes.oneOf(['lg','md','sm']),
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    row: PropTypes.string,
  }),
  reverse: PropTypes.bool,
};

UqFlexRow.defaultProps = {
  as: null,
  breakpoint: 'lg',
  reverse: false,
};

export function UqFlexRow(props) {
  const { as: Component, children, className, reverse, ...rest } = props;

  const sortedChildren = reverse ? [...children].reverse() : children;

  return (
    <>
      {renderRow(Component, sortedChildren, `d-none d-${props.breakpoint}-block`)}
      {renderRow(Component, children, `d-${props.breakpoint}-none`)}
    </>
  );

  function renderRow(Component, children, displayClassName) {
    const rootClasses = classNames(styles.root, displayClassName, className, props.classes?.root);

    const content = (
      <Row className={props.classes?.row}>
        {children.map((child, index) => (
          <React.Fragment key={index}>
            {child}
          </React.Fragment>
        ))}
      </Row>
    );

    if (Component) {
      return (
        <Component className={classNames(className, rootClasses)} {...rest}>
          {content}
        </Component>
      );
    }

    return (
      <section className={rootClasses}>
        {content}
      </section>
    );
  }
}
