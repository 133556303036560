import { useRef, useCallback } from 'react';

export function useVideo() {
  // Map([ [<id>, { player, setInactive }] ])
  const playerRefs = useRef(new Map());

  const playVideo = useCallback(async (id) => {
    const players = playerRefs.current;
    const videoPlayer = players.get(id).player;

    await videoPlayer?.ready();

    if (videoPlayer) {
      videoPlayer.play();

      for (const [key, ref] of playerRefs.current.entries()) {
        if (key !== id) {
          ref?.player?.pause();
          ref?.setInactive(true);
        }
      }
    }
  }, []);

  const pauseAll = useCallback(async () => {
    for (const ref of playerRefs.current.values()) {
      ref?.player?.pause();
      ref?.setInactive(true);
    }
  }, []);

  function registerVideoPlayer(id, ref) {
    if (ref) {
      playerRefs.current.set(id, ref);
    }
  }

  return {
    pauseAll,
    playVideo,
    registerVideoPlayer,
  };
}
