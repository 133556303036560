import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import { UqFade } from '../../../components/v2/layout/animation';

import * as styles from './hero.module.scss';
import { UqTextHighlight } from '../typography';

UqHero.propTypes = {
  classes: PropTypes.shape({
    imageCol: PropTypes.string,
    root: PropTypes.string,
    titleCol: PropTypes.string,
  }),
  image: PropTypes.node.isRequired,
  renderActions: PropTypes.func,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

UqHero.defaultProps = {
  renderActions: () => null,
};

export function UqHero(props) {
  const rootClasses = classNames(styles.root, props.classes?.root);

  return (
    <Row
      noGutters
      className={rootClasses}
    >
      <Col xl={6} lg={6} md={12}>
        <section className={classNames(styles.titleCol, props.classes?.titleCol)}>
          <h1 className={styles.title}>
            <UqTextHighlight>
              {props.title}
            </UqTextHighlight>
          </h1>
          <p className="body-2">
            {props.subtitle}
          </p>
          {props.renderActions()}
        </section>
      </Col>
      <Col xl={6} lg={6} md={12}>
        <section className={classNames(styles.imageCol, props.classes?.imageCol)}>
          <UqFade>
            <div className={styles.image}>
              {props.image}
            </div>
          </UqFade>
        </section>
      </Col>
    </Row>
  );
}
