import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { tileShape } from '../../../models';
import { UqTile } from './tile';

import * as styles from './tile-row.module.scss';

const ColumnClasses = new Map([
  [3, 'threeCol'],
  [2, 'twoCol'],
]);

UqTileRow.propTypes = {
  tileProps: PropTypes.shape({
    dark: PropTypes.bool,
    fontVariant: PropTypes.oneOf(['sm', 'lg']),
    variant: PropTypes.oneOf(['default', 'flat']),
  }),
  tiles: PropTypes.arrayOf(tileShape),
};

export function UqTileRow(props) {
  const isFlatVariant = props.tileProps?.variant === 'flat';

  return (
    <section className={classNames(styles.root, [styles[ColumnClasses.get(props.tiles.length)]])} >
      {props.tiles.map((v, index) => (
        <UqTile
          key={index}
          classes={{
            icon: isFlatVariant ? styles.flatIcon : undefined,
            root: styles.tile,
          }}
          dark={props.tileProps?.dark}
          description={v.desc?.desc}
          fontVariant={props.tileProps?.fontVariant}
          icon={v.icon.svg.content}
          title={v.title}
          variant={props.tileProps?.variant || 'default'}
        />
      ))}
    </section>
  );
}
