import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './text-highlight.module.scss';

export const HighlightDelimeters = {
  PRIMARY_COLOR: '**',
  ITALIC: '!*',
};

const HighlighterMap = new Map([
  [HighlightDelimeters.PRIMARY_COLOR, 'primary'],
  [HighlightDelimeters.ITALIC, 'italic'],
]);

UqTextHighlight.propTypes = {
  as: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  delimeter: PropTypes.oneOf(Object.values(HighlightDelimeters)),
  disabled: PropTypes.bool,
  renderHighlight: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'dark']),
};

UqTextHighlight.defaultProps = {
  delimeter: HighlightDelimeters.PRIMARY_COLOR,
  disabled: false,
};

/**
 * Highlights a title using the highlight delimeter. Default: **
 */
export function UqTextHighlight(props) {
  const { as: Component = 'div' } = props;

  const sections = props.children.split(props.delimeter);
  const highlightClassName = HighlighterMap.get(props.delimeter);

  return (
    <Component className={props.className}>
      {sections.map((value, index) => {
        // assumes that splitting on delimeter will always return 3 entries
        // in which the middle entry is the highlighted text
        const isHighlighted = index % 2 !== 0;

        if (!value) {
          return null;
        }

        return (
          <span
            key={index}
            className={classNames({
              [styles[highlightClassName]]: isHighlighted && !props.disabled,
              [styles.dark]: props.variant === 'dark',
            })}
          >
            {isHighlighted && props.renderHighlight
              ? props.renderHighlight(value)
              : value}
          </span>
        );
      })}
    </Component>
  );
}
