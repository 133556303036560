import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Figure } from 'react-bootstrap';

import * as styles from './figure-image.module.scss';

UqFigureImage.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.bool,
  src: PropTypes.string,
};

UqFigureImage.defaultProps = {
  fill: false,
};

export function UqFigureImage(props) {
  return (
    <section className={classNames(styles.root, props.className, { [styles.fill]: props.fill })}>
      <Figure.Image
        className={styles.image}
        src={props.src}
      >
      </Figure.Image>
    </section>
  );
}
