import { createContext } from 'react';

export const initialState = {
  /**
   * Video is currently active.
   * In overlay mode, this indicates the overlay is active.
   */
  active: false,

  /** Video config - @see UqVideo.propTypes */
  config: null,

  /** Video is currently playing, not paused */
  playing: false,

  /** Video has loaded and is ready to play */
  ready: false,
};

export const UqVideoContext = createContext(initialState);
