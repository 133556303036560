// extracted by mini-css-extract-plugin
export var about = "testimonial-card-module--about--69802";
export var action = "testimonial-card-module--action--1e917";
export var avatar = "testimonial-card-module--avatar--a9441";
export var back = "testimonial-card-module--back--daff8";
export var body = "testimonial-card-module--body--04f83";
export var card = "testimonial-card-module--card--b6d17";
export var content = "testimonial-card-module--content--d4e05";
export var flipable = "testimonial-card-module--flipable--8972c";
export var front = "testimonial-card-module--front--3349b";
export var hovered = "testimonial-card-module--hovered--86831";
export var logo = "testimonial-card-module--logo--1c977";
export var name = "testimonial-card-module--name--6f473";
export var quote = "testimonial-card-module--quote--dcd5f";
export var role = "testimonial-card-module--role--f09ff";
export var root = "testimonial-card-module--root--ba912";
export var title = "testimonial-card-module--title--cb6f2";