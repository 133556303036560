import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';

import { UqLegacyRequestDemoButton } from '../../../request-demo';
import { UqContainerV2 } from '../../../layout/container'
import { UqMenu } from '../../../menu';
import { UqLink } from '../../link';

import * as styles from './mobile-nav-overlay.module.scss';

UqMobileNavOverlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export function UqMobileNavOverlay(props) {
  const menuContext = useContext(UqMenu.Context);

  return (
    <div
      className={classNames('uq-mobile-nav', styles.root)}
      data-active={!!menuContext.get('open')}
    >
      <UqMenu.List
        className={styles.list}
        onClick={(event) => event.stopPropagation()}
      >
        {props.children}
      </UqMenu.List>
      <section
        className={classNames(styles.footer, {
          [styles.active]: menuContext.get('open'),
        })}
        role="navigation"
      >
        <UqContainerV2 className={styles.footerContainer}>
          <ul className={styles.secondaryNav}>
            <li>
              <UqLink
                as={Button}
                className="wide"
                to="https://monitor.unitq.com/login"
                external
                metadata={{ variant: 'outline-secondary' }}
              >
                Login
              </UqLink>
            </li>
            <li>
              <UqLegacyRequestDemoButton className="wide" />
            </li>
          </ul>
        </UqContainerV2>
      </section>
    </div>
  );
}
