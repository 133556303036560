export const Content = {
  values: [
    {
      icon: '/images/v2/careers/icons/unitq-logo.svg',
      title: 'Q is for Quality',
      subtitle: `Quality is the core of our company's DNA.
        From the people we hire to the products we build, we emphasize quality in everything we do.`,
    },
    {
      icon: '/images/v2/careers/icons/customer.svg',
      title: 'Customers first',
      subtitle: `We keep our customers and their experience at the forefront of
        product development - striving to build products that solve real needs.`,
    },
    {
      icon: '/images/v2/careers/icons/love.svg',
      title: 'We love what we do',
      subtitle: `We're passionate about our work.
        We love nerding out on quality and dedicating ourselves to delivering great products to our customers.`,
    },
    {
      icon: '/images/v2/careers/icons/culture.svg',
      title: 'Culture matters, too',
      subtitle: `We're proud to have a team with great integrity and accountability.
        We believe in ownership and work hard to meet our individual milestones.`,
    },
  ],
  title: 'We\'re driven by our **core** values',
};
