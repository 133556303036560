import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getRating } from '../../../../helpers/v2/score-helper';

import * as styles from './score.module.scss';

UqScore.propTypes = {
  score: PropTypes.number,
};

export function UqScore(props) {
  const rating = useMemo(() => getRating(props.score), [props.score]);

  return (
    <div className={classNames(styles.root, styles[rating])}>
      {`${props.score} ${rating}`}
    </div>
  );
}

