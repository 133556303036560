import PropTypes from 'prop-types';

export const integrationShape = PropTypes.shape({
  logo: PropTypes.shape({
    svg: PropTypes.shape({
      content: PropTypes.string,
    }),
  }),
  name: PropTypes.string,
});
