const DEFAULT_VIMEO_SETTINGS = {
  vimeo: {
    playerOptions: {
      cc: false,
      fullscreen: true,
      interactive_markers: false,
      pip: false,
      quality_selector: false,
      speed: false,
      transcript: false,
      vimeo_logo: false,
    },
  } ,
};

const VideoSettingsMap = new Map([
  ['vimeo', DEFAULT_VIMEO_SETTINGS],
]);

export function getDefaultVideoSettings(type) {
  return VideoSettingsMap.get(type) || null;
}
