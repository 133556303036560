import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as styles from './flex-grid.module.scss';

UqFlexGrid.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export function UqFlexGrid(props) {
  return (
    <section className={classNames(styles.root, props.classes?.root)}>
      {props.children}
    </section>
  );
}
