import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqExpansionPanelContext } from './context';

import * as styles from './expansion-panel-content.module.scss';

ExpansionPanelContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export function ExpansionPanelContent(props) {
  const context = useContext(UqExpansionPanelContext);

  return (
    <div
      id={`${context.get('id')}-content`}
      className={classNames('uq-expansion-panel-content', 'caption-1', styles.root)}
    >
      <div className={styles.transition}>
        {props.children}
      </div>
    </div>
  );
}
