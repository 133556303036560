import React, { useEffect, useContext, useRef, useState, forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useVideoManager } from '@uq-hooks';
import { UqLink } from '../../../navigation';
import { UqVideoContext } from './context';

import * as styles from './video-player.module.scss';

export const VideoPlayer = forwardRef((props, ref) => {
  const context = useContext(UqVideoContext);
  const videoManager = useVideoManager();

  const config = context.get('config');

  const internalRef = useRef(null);
  const [generatedId] = useState(`${config.url}_${Date.now()}`);

  const activeRef = ref || internalRef;

  const onEnded = () => {
    props.onEnded?.();

    if (config.preview) {
      activeRef.current.showPreview();
    }
  };

  const onPause = () => context.update('playing', false);
  const onPlay = () => videoManager.activate(generatedId);
  const onReady = () => context.update('ready', true);
  const onClickPreview = () => context.update('playing', true);

  useEffect(() => {
    videoManager.register(generatedId, activeRef);
    context.update('playing', !!config.autoplay);

    return () => videoManager.unregister(generatedId);
  }, []);

  return (
    <div className={styles.container}>
      <ReactPlayer
        ref={activeRef}
        className={classNames(props.className, styles.root)}
        controls
        config={config.settings}
        light={config.preview}
        onClickPreview={onClickPreview}
        onEnded={onEnded}
        onPause={onPause}
        onReady={onReady}
        onPlay={onPlay}
        playIcon={renderPlayButton()}
        playing={context.get('playing')}
        muted={props.mute}
        height={props.height || '100%'}
        width={props.width || '100%'}
        url={config.url}
      />
    </div>
  );

  function renderPlayButton() {
    return (
      <div className={classNames('caption-1', styles.playButtonWrapper)}>
        <UqLink
          as={Button}
          onClick={() => null}
          className={styles.playButton}
        >
          Watch Video
          <span className={classNames('material-symbols-outlined', styles.arrow)}>
            chevron_right
          </span>
        </UqLink>
      </div >
    );
  }
});

VideoPlayer.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  mute: PropTypes.bool,
  onEnded: PropTypes.func,
  width: PropTypes.number,
};

VideoPlayer.defaultProps = {
  mute: true,
};
