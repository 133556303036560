import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as styles from './hero.module.scss';

UqHeroV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

export function UqHeroV2(props) {
  return (
    <section className={classNames(styles.root, props.className)}>
      {props.children}
    </section>
  );
}
