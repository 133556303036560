import { useCallback } from 'react';
import { isSSR } from '@uq-helpers';

export function useElementById(id) {
  return useCallback(() => {
    if (isSSR()) {
      return undefined;
    }

    return window.document.getElementById(id);
  }, []);
}
