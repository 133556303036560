// extracted by mini-css-extract-plugin
export var bar = "nav-toggle-module--bar--50230";
export var bottom = "nav-toggle-module--bottom--68305";
export var box = "nav-toggle-module--box--80b8f";
export var center = "nav-toggle-module--center--2e25f";
export var dark = "nav-toggle-module--dark--6d219";
export var icon = "nav-toggle-module--icon--55db4";
export var light = "nav-toggle-module--light--26c29";
export var open = "nav-toggle-module--open--eeb74";
export var root = "nav-toggle-module--root--aeebd";
export var top = "nav-toggle-module--top--914d4";