import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import { GatsbyImage, StaticImage, getImage as getGatsbyImage } from 'gatsby-plugin-image';

import { UqAvatar, UqSvg } from '@uq-components';
import * as styles from './zendesk-quote.module.scss';

const BACKGROUND_SRC = '../../../../images/customers/zendesk/zendesk-quote-background.jpg';

const query = graphql`
  query {
    data: contentfulTestimonial(slug: {eq: "zendesk-shawn-slipy"}) {
      zendesk: customer {
        spotlightLogo {
          svg {
            content: originalContent
          }
        }
      }
      quote {
        quote
      }
      author {
        company
        image {
          gatsbyImageData(
            formats: [AVIF, WEBP]
            placeholder: DOMINANT_COLOR
            width: 64
          )
        }
        name
        role
      }
    }
  }
`;

export function UqZendeskQuote() {
  const { data } = useStaticQuery(query);

  const role = `${data.author.role}, ${data.author.company}`;

  return (
    <article className={styles.root}>
      <StaticImage
        alt="zendesk quote background"
        className={styles.background}
        src={BACKGROUND_SRC}
        placeholder="dominantColor"
        style={{ position: 'absolute' }}
      />

      <section className={styles.content}>
        <UqSvg className={styles.logo}>
          {data.zendesk.spotlightLogo.svg.content}
        </UqSvg>
        <blockquote className={styles.quote}>
          <p>{data.quote.quote}</p>
        </blockquote>
        <section className={styles.avatarContainer}>
          <UqAvatar
            className={styles.avatar}
            name={data.author.name}
            role={role}
          >
            <UqAvatar.Image>
              <GatsbyImage
                alt={role}
                image={getGatsbyImage(data.author.image)}
              />
            </UqAvatar.Image>

            <UqAvatar.Content>
              <div className={classNames(styles.name, 'body-2')}>
                {data.author.name}
              </div>
              <div className="caption-2">
                {role}
              </div>
            </UqAvatar.Content>
          </UqAvatar>
        </section>
      </section>
    </article>
  );
}
