import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './customer-profile.module.scss';

UqCustomerProfile.propTypes = {
  logo: PropTypes.node,
};

export function UqCustomerProfile(props) {
  return (
    <aside className={classNames(styles.root, 'panel')}>
      <section className={styles.logo}>
        {props.logo}
      </section>
      <section className="body-1">
        {props.children}
      </section>
    </aside>
  );
}
