import React from 'react';

import { Content } from './content';
import * as styles from './footer-nav-bar.module.scss';

export function UqFooterNavbarV2() {
  return (
    <nav>
      <ul className={styles.links}>
        {Content.items.map((v, index) => (
          <UqFooterNavbarV2.Item
            key={index}
            item={v}
          />
        ))}
      </ul>
    </nav>
  );
}
