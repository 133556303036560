import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqTextHighlight } from '../typography';
import { Content } from './content';
import { UqSolutionsTile } from './solutions-tile';
import { ExploreTile } from './explore-tile';

import Confetti from '@uq-assets/confetti.svg';

import * as styles from './solutions-block.module.scss';

UqSolutionsBlock.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.string,
  }),
  title: PropTypes.string,
  withConfetti: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'condensed']),
};

UqSolutionsBlock.defaultProps = {
  withConfetti: false,
  variant: 'default',
};

export function UqSolutionsBlock(props) {
  const isCondensed = props.variant === 'condensed';

  return (
    <section className={classNames(styles.root, { [styles.condensed]: isCondensed })}>
      <h2 className={classNames(styles.title, props.classes?.title)}>
        <UqTextHighlight>
          {props.title}
        </UqTextHighlight>
      </h2>
      <div className={styles.gridContainer}>
        <div className={classNames(styles.grid, { [styles.condensed]: isCondensed })}>
          {Content.solutions.map((solution, index) => (
            <UqSolutionsTile
              key={index}
              condensed={isCondensed}
              {...solution}
            />
          ))}
          <ExploreTile variant={props.variant} />
        </div>
      </div>
      {props.withConfetti && <Confetti className={styles.confetti} />}
    </section>
  );
}
