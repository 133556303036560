import { UqCookieLink } from './cookie-link';

export const Content = {
  items: [

    // Column 1
    [
      {
        label: 'Product',
        links: [
          { href: '/product', id: 'overview', label: 'Overview' },
          { href: '/agentq', id: 'agentq', label: 'agentQ' },
          { href: '/integrations?filter=all', id: 'integrations', label: 'Integrations' },
          { href: '/legal-security', id: 'security', label: 'Security' },
          {
            external: true,
            href: 'https://get.unitq.com/hubfs/Legal/unitQ AI Statement of Principles and Governance.pdf',
            id: 'ai-governance',
            label: 'AI Governance',
          },
          { href: '/aws', id: 'aws', label: 'AWS' },
        ],
      },
    ],

    // Column 2
    [
      {
        label: 'Solutions',
        links: [
          { href: '/solutions/support', id: 'cx', label: 'CX' },
          { href: '/solutions/engineering', id: 'engineering', label: 'Engineering' },
          { href: '/solutions/leadership', id: 'leadership', label: 'Leadership' },
          { href: '/solutions/product-operations', id: 'product', label: 'Product' },
          { href: '/solutions/marketing', id: 'marketing', label: 'Marketing' },
        ],
      },
      {
        label: 'Customers',
        href: '/customers',
      },
    ],

    // Column 3
    [
      {
        label: 'unitQ Score',
        links: [
          { href: '/unitq-scorecards', id: 'scorecards', label: 'Scorecard List' },
        ],
      },
      {
        label: 'Company',
        links: [
          { href: '/about-us/', id: 'about', label: 'About' },
          { href: '/news/', id: 'news', label: 'News' },
          { href: '/careers/', id: 'careers', label: 'Careers' },
        ],
      },
    ],

    // Column 4
    [
      {
        label: 'Resources',
        links: [
          { href: '/blog', id: 'blog', label: 'Blog' },
          { href: '/resources', id: 'scorecards', label: 'Library' },
          {
            external: true,
            href: 'https://help.unitq.com/hc/en-us',
            id: 'help',
            label: 'Help Center',
          },
        ],
      },
    ],
  ],

  resources: [
    { href: '/legal-terms', id: 'terms-and-conditions', label: 'Terms & Conditions' },
    { href: '/legal-privacy', id: 'privacy-policy', label: 'Privacy Policy' },
    { component: UqCookieLink, id: 'cookies' },
    {
      external: true,
      href: 'https://status.unitq.com/',
      id: 'service-status',
      label: 'Service Status',
    },
  ],
};
