import React from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';

import * as styles from './values-tile.module.scss';

UqValuesTile.propTypes = {
  condensed: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

UqValuesTile.defaultProps = {
  condensed: false,
};

export function UqValuesTile(props) {

  return (
    <div className={styles.root}>
      <img src={props.icon} alt={props.title} />
      <span className={styles.title}>{props.title}</span>
      {!props.condensed && renderDetails()}
    </div>
  );

  function renderDetails() {
    return (
      <p className="body-1">{props.subtitle}</p>
    );
  }
}
