import React from 'react';

import { UqScrollRevealAnimation } from './scroll-reveal-animation';

UqFade.propTypes = {
  ...UqScrollRevealAnimation.propTypes,
};

UqFade.defaultProps = {
  delay: 150,
  duration: 2000,
  easing: 'ease',
};

export function UqFade(props) {
  return (
    <UqScrollRevealAnimation
      type="fade"
      {...props}
    >
      {props.children}
    </UqScrollRevealAnimation>
  );
}
