import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqQuoteAuthor } from './quote-author'; 

import * as styles from './quote.module.scss';

UqQuote.propTypes = {
  authorProps: PropTypes.shape({
    avatar: PropTypes.elementType, 
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
  }),
  classes: PropTypes.shape({
    quote: PropTypes.string,
  }),
  children: PropTypes.string.isRequired,
  logo: PropTypes.element,
};

export function UqQuote(props) {
  const rootClasses = classNames('new-uq-theme', styles.root);
  const quoteClasses = classNames(props.classes?.quote, styles.quote);

  return (
    <section className={rootClasses}>
      <div className={styles.logo}>{props.logo}</div>
      <q className={quoteClasses}>{props.children}</q>
      {props.authorProps && (
        <UqQuoteAuthor {...props.authorProps} />
      )}
    </section>
  );
}
