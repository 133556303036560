import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { UqMenu } from '../../../menu';

import * as styles from './nav-toggle.module.scss';

UqNavToggle.propTypes = {
  open: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
};

export function UqNavToggle(props) {
  const menuContext = useContext(UqMenu.Context);

  const barClasses = classNames(styles.bar, styles[props.theme]);

  return (
    <div
      role="button"
      className={styles.root}
    >
      <div className={classNames(styles.icon, { [styles.open]: menuContext.get('open') })}>
        <div className={classNames(barClasses, styles.top)}></div>
        <div className={classNames(barClasses, styles.center)}></div>
        <div className={classNames(barClasses, styles.bottom)}></div>
      </div>
    </div>
  );
}
