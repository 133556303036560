import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './grid-row.module.scss';

UqGridRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
};

export function UqGridRow(props) {
  return (
    <section className={classNames(styles.root, props.className)}>
      {props.children}
    </section>
  );
}
