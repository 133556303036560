import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';

import { UqLink } from '../navigation';
import ArrowIcon from '@uq-assets/arrow-right.svg';

import * as styles from './promo-banner.module.scss';

const query = graphql`
 query {
   banner: allGoogleSheetUnitQSourceYellowBannerDataSource {
      edges {
        node {
          actionLabel
          endDate
          link
          message
          startDate
        }
      }
    }
  }
`;

export function UqPromoBanner() {
  const queryResult = useStaticQuery(query);
  const banner = queryResult.banner.edges[0].node;

  const showPromoBanner = useMemo(() => {
    const startDate = new Date(banner?.startDate).getTime();
    const endDate = new Date(banner?.endDate).getTime();
    const currentDate = new Date().getTime();

    return currentDate >= startDate && currentDate < endDate;
  }, [banner]);

  if (!banner || !showPromoBanner) {
    return null;
  }

  return (
    <UqLink
      className={classNames('new-uq-theme', styles.root)}
      to={banner.link}
      external
    >
      <span className="caption-1">{banner.message}</span>
      <span className={styles.action}>
        {banner.actionLabel}
      </span>
      <ArrowIcon className={styles.arrow} />
    </UqLink>
  );
}
