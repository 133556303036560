import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './article-quote.module.scss';

UqArticleQuote.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export function UqArticleQuote(props) {
  const quote = <q className={styles.quote}>{props.children}</q>;

  if (!props.author) {
    return quote;
  }

  return (
    <section className={styles.root}>
      {quote}
      {props.author && (
        <div className={styles.author}>
          {`– ${props.author.name}, ${props.author.role}`}
        </div>
      )}
    </section>
  );
}
