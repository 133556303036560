import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';

import * as styles from './score-card-group.module.scss';

UqScoreCardGroup.propTypes = {
  apps: PropTypes.array.isRequired,
  groupKey: PropTypes.string.isRequired,
};

export function UqScoreCardGroup(props) {
  return (
    <div className={styles.root}>
      <h2>{props.groupKey}</h2>
      <ul className={styles.list}>
        {props.apps.map((v, i) => (
          <li key={i}>
            <Nav.Link
              className={styles.link}
              href={`/unitq-scorecards/${v.id}/`}
            >
              {v.title}
            </Nav.Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
