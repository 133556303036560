import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GatsbyImage, getImage as getGatsbyImage } from 'gatsby-plugin-image';

import { isExternalUrl } from '../../../helpers/v2';
import { useHover } from '../../../hooks/v2';

import { UqLink } from '../navigation';

import * as styles from './link-card.module.scss';

UqLinkCard.propTypes = {
  date: PropTypes.string,
  thumbnail: PropTypes.shape({
    gatsbyImageData: PropTypes.shape(),
    url: PropTypes.string,
  }),
  title: PropTypes.string,
  url: PropTypes.string,
};

export function UqLinkCard(props) {
  const { isHovered, hoverTriggers } = useHover();

  return (
    <UqLink
      className={styles.root}
      to={props.url}
      external={isExternalUrl(props.url)}
      hideArrow
      variant="inherit"
    >
      <section
        className={styles.imageContainer}
        {...hoverTriggers}
      >
        <GatsbyImage
          alt={props.title}
          image={getGatsbyImage(props.thumbnail)}
          src={props.thumbnail.url}
        />
      </section>
      <section
        className={styles.content}
        {...hoverTriggers}
      >
        <section>
          <section className={classNames('caption-1', styles.date)}>
            {props.date}
          </section>
          <section className={classNames('body-2-strong', styles.title)}>
            {props.title}
          </section>
        </section>

        <span className={styles.arrow}>
          <UqLink.Arrow active={isHovered} />
        </span>
      </section>
    </UqLink>
  );
}
