import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './figure-content.module.scss';

UqFigureContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  fill: PropTypes.bool,
};

UqFigureContent.defaultProps = {
  fill: false,
};

export function UqFigureContent(props) {
  return (
    <section className={classNames(styles.root, props.className, { [styles.fill]: props.fill })}>
      {props.children}
    </section>
  );
}
