import React from 'react';
import PropTypes from 'prop-types';

import SEO from '../../../components/seo';

UqPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  config: PropTypes.shape({
    seo: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      src: PropTypes.string,
    }),
  }),
};

export function UqPage(props) {
  const seo = props.config?.seo;

  return (
    <>
      {seo && (
        <SEO
          appendHostUrl={seo.appendHostUrl != null ? seo.appendHostUrl : false}
          title={seo.title}
          description={seo.description}
          image={seo.src}
        />
      )}
      {props.children}
    </>
  );
}
