import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useUqContext } from '@uq-hooks';
import { UqMenuContext, initialState } from './context';

import * as styles from './menu.module.scss';

UqMenu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
};

export function UqMenu(props) {
  const context = useUqContext(initialState, { open: props.open });

  const toggleMenu = useCallback(() => {
    const next = !context.get('open');

    context.update('open', next);

    if (!next) {
      props.onClose?.();
    } else {
      props.onOpen?.();
    }
  }, [context]);

  return (
    <UqMenuContext.Provider value={context}>
      <div
        className={classNames(styles.root, props.className)}
        onClick={toggleMenu}
      >
        {props.children}
      </div>
    </UqMenuContext.Provider>
  );
}
