import { useEffect, useState, useCallback } from 'react';

export function useInterval(onInterval) {
  // 'playing', 'paused', 'stopped'
  const [state, setState] = useState('playing');

  const pause = useCallback(() => {
    if (state === 'stopped') {
      return;
    }

    setState('paused');
  }, [state]);

  const resume = useCallback(() => {
    if (state === 'stopped') {
      return;
    }

    setState('playing');
  }, [state]);

  useEffect(() => {
    if (state === 'paused' || state === 'stopped') {
      return;
    }

    const id = setInterval(() => {
      onInterval();
    }, 3000);

    return () => clearInterval(id);
  }, [onInterval, state]);

  return {
    controls: {
      pause,
      play: () => setState('playing'),
      stop: () => setState('stopped'),
      resume,
    },
    state,
  }
}
