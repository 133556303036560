import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import * as styles from './arrow.module.scss';

LinkArrow.propTypes = {
  active: PropTypes.bool,
  parent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.object }),
  ]),
  size: PropTypes.oneOf(['default', 'md', 'lg']),
  variant: PropTypes.oneOf(['default', 'dark']),
};

export function LinkArrow(props) {
  return (
    <span className={classNames(styles.root, {
      [styles.active]: props.active,
      [styles.dark]: props.variant === 'dark',

    })}>
      <span className={classNames('material-symbols-outlined', styles.head)}>
        chevron_right
      </span>
      <span className={styles.tail} />
    </span >
  );
}
