import { useState } from 'react';
import throttle from 'lodash/throttle';

import { useEventListener } from './use-event-listener';
import { useMountEffect } from './use-mount-effect';

export function useScroll(threshold = 0) {
  const [isScrolled, setScrolled] = useState(false);

  const scroll = throttle(() => {
    setScrolled(window?.scrollY > threshold);
  }, 100);

  useEventListener(null, 'scroll', scroll);

  useMountEffect(() => scroll());

  return isScrolled;
}
