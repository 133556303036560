
import { useState, useMemo } from 'react';
import { get as lodashGet } from 'lodash';

import { createContextState, updateContext } from '@uq-helpers';

export function useUqContext(initialState, overrides = {}, api = {}) {
  const [baseState, setBaseState] = useState(initialState);

  const state = useMemo(() => createContextState(baseState, overrides), [baseState, overrides]);

  // @todo move all of these custom functions to the api
  return useMemo(() => ({
    api,
    get: (path) => lodashGet(state, path, state),
    reset: () => setBaseState(initialState),
    update: (path, value) => setBaseState(updateContext(state, path, value)),
  }), [state]);
}
