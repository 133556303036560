import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowIcon from '@uq-assets/arrow-right.svg';

import * as styles from './quote-carousel-control.module.scss';

UqQuoteCarouselControl.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
};

export function UqQuoteCarouselControl(props) {
  const iconClasses = classNames(styles.icon, {
    [styles.left]: props.direction === 'left',
  });

  return (
    <div className={styles.root}>
      <ArrowIcon className={iconClasses} />
    </div>
  );
}
