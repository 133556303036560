import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './nav-menu-items.module.scss';

UqNavMenuItems.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  variant: PropTypes.oneOf(['list', 'grid']),
};

UqNavMenuItems.defaultProps = {
  variant: 'list',
};

export function UqNavMenuItems(props) {
  const gridClass = { [styles.grid]: props.variant === 'grid' };

  return (
    <div
      role="navigation"
      className={classNames(styles.root, gridClass)}
    >
      {props.title && (
        <span className={classNames(styles.linksTitle, 'caption-1')}>
          {props.title}
        </span>
      )}
      <ul className={classNames(styles.linksContainer, gridClass)}>
        {props.children}
      </ul>
    </div>
  );
}
