import React from 'react';
import PropTypes from 'prop-types';

import { NAV_ICONS } from './content';
import * as styles from './nav-menu-icon.module.scss';

UqNavMenuIcon.propTypes = {
  children: PropTypes.string.isRequired,
}

export function UqNavMenuIcon(props) {
  const iconId = props.children;
  const Icon = NAV_ICONS[iconId];

  return <Icon className={styles[iconId]} />;
}
