export function getBlogCategory(blogEntity) {
  return blogEntity.categories?.[0] || null;
}

const CategoriesMap = new Map([
  ['All', 'all'],
  ['Best Practices', 'best-practices'],
  ['Culture', 'culture'],
  ['Customer Stories', 'customer-stories'],
  ['Industry', 'industry'],
  ['Leadership', 'leadership'],
  ['News', 'news'],
  ['Product', 'product'],
  ['Product Releases', 'product-releases'],
  ['Resources', 'resources'],
  ['Tutorials', 'tutorials'],
  ['unitQ Voices', 'uq-voices'],
]);

export function adaptBlogCategoryId(category) {
  return CategoriesMap.get(category);
}

export function createBlogCategoryOptions() {
  const options = [];

  for (const [label, key] of CategoriesMap) {
    options.push({ key, label });
  }

  return options;
}

export function getBlogImage(blog) {
  return blog.thumbnail || blog.coverImage;
}
