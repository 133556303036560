import PropTypes from 'prop-types';

const richTextNode = {
  data: PropTypes.object,
  marks: PropTypes.array,
  nodeType: PropTypes.string,
  value: PropTypes.string,
};

richTextNode.content = PropTypes.arrayOf(PropTypes.shape(richTextNode));

export const RichTextNodeShape = PropTypes.shape(richTextNode);
