import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card } from 'react-bootstrap';

import { UqQuote } from './quote';

import * as styles from './quote-card.module.scss';

UqQuoteCard.propTypes = {
  author: PropTypes.shape({
    avatar: PropTypes.elementType,
    name: PropTypes.string.isRequired,
    role: PropTypes.string,
  }),
  children: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['blue', 'green', 'purple', 'red', 'yellow']),
  logo: PropTypes.elementType,
};

UqQuoteCard.defaultProps = {
  color: 'green',
};

export function UqQuoteCard(props) {
  const Logo = props.logo;
  const cardClasses = classNames(styles[props.color], styles.card);

  return (
    <Card className={cardClasses}>
      <Card.Body className={styles.cardBody}>
        <UqQuote
          authorProps={props.author}
          logo={Logo && <Logo />}
        >
          {props.children}
        </UqQuote>
      </Card.Body>
    </Card>
  );
}
