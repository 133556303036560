import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqTextHighlight } from '../typography';

import * as styles from './figure-title.module.scss';

UqFigureTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fill: PropTypes.bool,
  variant: PropTypes.oneOf(['h2', 'h4']),
};

UqFigureTitle.defaultProps = {
  fill: false,
  variant: 'h2',
};

export function UqFigureTitle(props) {
  return (
    <UqTextHighlight
      as={props.variant}
      className={classNames(styles.root, props.className, { [styles.fill]: props.fill })}
    >
      {props.children}
    </UqTextHighlight>
  );
}
