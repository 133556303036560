import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { useVideo } from '../../../../hooks/v2';
import { svgShape } from '../../../../models';
import { UqCarousel } from '../../carousel';
import { UqVideoPlayer } from '../../media';
import { CARD_COLORS, UqVideoTestimonialCard } from './video-testimonial-card';

import * as styles from './testimonial-videos.module.scss';

UqTestimonialVideos.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.shape({
        company: PropTypes.string,
        name: PropTypes.string,
        role: PropTypes.string,
        thumbnail: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      color: PropTypes.oneOf(CARD_COLORS),
      customer: PropTypes.shape({
        spotLightLogo: svgShape,
      }),
      videoUrl: PropTypes.string,
    }),
  ),
};

export function UqTestimonialVideos(props) {
  const carouselRef = useRef(null);

  const { pauseAll, playVideo, registerVideoPlayer } = useVideo();

  const carouselItems = useMemo(() => renderItems({ hideDetails: false, scrollIntoView: false }), []);

  return (
    <section>
      <div className={styles.carousel}>
        <UqCarousel
          ref={carouselRef}
          onPlay={() => pauseAll()}
        >
          {carouselItems}
        </UqCarousel>
      </div>
      <div className={styles.carouselMobile}>
        {renderItems({ hideDetails: true, scrollIntoView: true })}
      </div>
    </section>
  );

  function renderItems(config) {
    return props.videos.map((v, index) => (
      <div key={index}>
        <UqVideoPlayer
          key={index}
          classes={{ root: styles.video }}
          ref={(ref) => registerVideoPlayer(v.customer.name, ref)}
          id={v.customer.name}
          config={v.playerProps}
          initialState="inactive"
          disableAnimation
          thumbnail={(
            <UqVideoTestimonialCard
              color={v.color}
              hideDetails={config.hideDetails}
              testimonial={v}
            />
          )}
          onPlay={() => {
            carouselRef.current.controls.stop();
            playVideo(v.customer.name);
          }}
          scrollIntoView={config.scrollIntoView}
        />
        {config.hideDetails && (
          <div className={styles.about}>
            <span className={styles.name}>
              {v.author.name}
            </span>
            <span>{v.author.role}</span>
          </div>
        )}
      </div>
    ));
  }
}
