import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqSvg } from '../media';

import * as styles from './tile.module.scss';

UqTile.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string,
    root: PropTypes.string,
  }),
  dark: PropTypes.bool,
  description: PropTypes.string,
  // expects the svg as a content string from Contentful
  icon: PropTypes.string,
  title: PropTypes.string,
  fontVariant: PropTypes.oneOf(['sm', 'lg']),
  variant: PropTypes.oneOf(['default', 'flat']),
};

UqTile.defaultProps = {
  dark: false,
  fontVariant: 'lg',
  variant: 'default',
};

export function UqTile(props) {
  return (
    <section
      className={classNames(
        styles.root,
        props.classes?.root,
        [styles[props.fontVariant]],
        {
          [styles.dark]: props.dark,
          [styles.flat]: props.variant === 'flat',
        },
      )}
    >
      <div className={styles.titleContainer}>
        <UqSvg className={classNames(styles.icon, props.classes?.icon)}>
          {props.icon}
        </UqSvg>
        <h3 className={classNames(styles.title, { [styles.sm]: props.fontVariant === 'sm' })}>
          {props.title}
        </h3>
      </div>
      <p className={classNames('body-3', styles.description)}>
        {props.description}
      </p>
    </section>
  );
}
