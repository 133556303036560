import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import { UqLayoutContext } from '../../layout';

import * as styles from './container.module.scss';


UqContainerV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  fill: PropTypes.bool,
  id: PropTypes.string,
  overflowX: PropTypes.bool,
  overflowY: PropTypes.bool,
  theme: PropTypes.oneOf(['light', 'dark']),
};

UqContainerV2.defaultProps = {
  fill: false,
  overflowX: false,
  overflowY: false,
};

export function UqContainerV2(props) {
  const defaultClassNames = ['new-uq-theme', 'v2'];

  const layoutContext = useContext(UqLayoutContext);

  return (
    <Container
      id={props.id}
      className={
        classNames(
          ...defaultClassNames,
          props.className || '',
          [styles[props.theme || layoutContext.theme]], {
            [styles.fill]: props.fill,
            [styles.overflowY]: props.overflowY,
          },
        )
      }
    >
      {props.fill
        ? renderWithWrapper(props.children)
        : props.children}
    </Container>
  );

  function renderWithWrapper(element) {
    return (
      <section
        className={
          classNames('uq-inner-container', styles.innerContainer, {
            [styles.overflowX]: props.overflowX,
          })
        }
      >
        {element}
      </section>
    );
  }
}
