import { createContext } from 'react';

export const initialState = {
  active: false,
  isHovered: false,
  isScrolled: false,
  theme: 'light',
};

export const UqAppbarContext = createContext(initialState);
