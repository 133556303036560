import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useHover } from '../../../hooks/v2';
import { UqNavIconLink, UqLink } from '../navigation';

import * as styles from './solutions-tile.module.scss';

UqSolutionsTile.propTypes = {
  condensed: PropTypes.bool,
  ctaText: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

UqSolutionsTile.defaultProps = {
  condensed: false,
};

export function UqSolutionsTile(props) {
  const { isHovered, hoverTriggers } = useHover();

  const rootClasses = classNames(styles.root, { [styles.condensed]: props.condensed });
  const iconClasses = classNames(styles.solutionsIcon, { [styles.condensed]: props.condensed });

  return (
    <span {...hoverTriggers}>
      <UqNavIconLink
        classes={{
          root: rootClasses,
          icon: iconClasses,
        }}
        href={props.href}
        icon={props.icon}
      >
        {!props.condensed && (
          <div className={styles.container}>
            <div className={styles.content}>
              <h3 className={styles.title}>
                {props.title}
              </h3>
              <p className="body-1">
                {props.subtitle}
              </p>
            </div>
            <section className={classNames(styles.link, { [styles.active]: isHovered })}>
              {props.ctaText}
              <UqLink.Arrow active={isHovered} />
            </section>
          </div>
        )}
        {props.condensed && (
          <div className={styles.condensedContainer}>
            <h3 className={classNames(styles.title, { [styles.condensed]: props.condensed })}>
              {props.title}
            </h3>
            <span className={styles.arrow}>
              <UqLink.Arrow active={isHovered} />
            </span>
          </div>
        )}
      </UqNavIconLink>
    </span>
  );
}
