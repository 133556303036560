import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { UqMenu } from '../../../menu';
import { UqLink } from '../../link';
import { UqMobileNavContext } from './context';

import * as styles from './mobile-nav-item.module.scss';

UqMobileNavItem.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  item: PropTypes.shape({
    href: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        to: PropTypes.string,
      }),
    ),
    titles: PropTypes.shape(),
    variant: PropTypes.string,
  }),
};

export function UqMobileNavItem(props) {
  const context = useContext(UqMobileNavContext);

  const toggleInnerMenu = useCallback(() => {
    let value = null;

    if (context.get('activeMenu') !== props.id) {
      value = props.id;
    }

    context.update('activeMenu', value);
  }, [context, props.id]);

  return (
    <li className={classNames(styles.root, props.className)}>
      {props.item.links?.length
        ? renderMenu()
        : (
          <UqLink
            className="body-2-strong"
            to={props.item.href}
            hideArrow
            external={props.item.external}
            variant="inherit"
          >
            {props.children}
          </UqLink>
        )}
    </li>
  );

  function renderMenu() {
    return (
      <UqMenu open={context.get('activeMenu') === props.id}>
        <UqMenu.Toggle
          as="div"
          onClick={toggleInnerMenu}
        >
          <span className="body-2-strong">
            {props.children}
          </span>
        </UqMenu.Toggle>

        <UqMenu.List onClick={(event) => event.stopPropagation()}>
          {props.item.links.map((v, index) => (
            <UqMenu.Item
              key={index}
              className={styles.innerItem}
            >
              <UqLink
                className="body-2"
                to={v.href}
                hideArrow
                external={v.external}
                variant="inherit"
              >
                {v.label}
              </UqLink>
            </UqMenu.Item>
          ))}
        </UqMenu.List>
      </UqMenu>
    );
  }
}
