const HOVERABLE_DEVICE_QUERY = '(hover: hover) and (pointer: fine)';

/** Detects if the device has hover functionality available */
export function useDevice() {
  if (typeof window === 'undefined') {
    return {};
  }

  return {
    isHoverEnabled: window.matchMedia(HOVERABLE_DEVICE_QUERY).matches,
  };
}
