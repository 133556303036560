import React from 'react';
import PropTypes from 'prop-types';

import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

UqToggle.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export function UqToggle(props) {
  return (
    <ToggleButtonGroup
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    >
      {props.options.map((v, index) => (
        <ToggleButton
          key={index}
          value={v.value}
        >
          {v.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
