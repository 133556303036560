import { useEffect } from 'react';

export function useEventListener(ref, eventName, callback) {
  useEffect(() => {
    const el = ref ? ref.current : window;

    if (!ref && !window) {
      return;
    }

    el.addEventListener(eventName, callback);

    return () => {
      el.removeEventListener(eventName, callback);
    };
  }, [callback]);
}
