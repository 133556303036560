import { useState } from 'react';
import { useDevice } from './use-device';

export function useHover() {
  const [isHovered, setHovered] = useState(false);
  const device = useDevice();

  return {
    hoverTriggers: {
      onMouseEnter,
      onMouseLeave,
    },
    isHovered: device.isHoverEnabled && isHovered,
    setHovered,
  };

  function onMouseEnter() {
    setHovered(true);
  }

  function onMouseLeave() {
    setHovered(false);
  }
}
