import React from 'react';
import classNames from 'classnames';

import CtoBoardroomMediaAward from './assets/cto-boardroom-media.svg';
import FastCompanyAward from './assets/fast-company.svg';
import G2Award from './assets/g2.svg';
import InsightsAward from './assets/insights.svg';
import StevieAward from './assets/stevie.svg';

import * as styles from './awards.module.scss';

export function UqAwards() {
  return (
    <article className={styles.root}>
      <span className={classNames(styles.title, 'body-2')}>
        Awards & Recognition
      </span>
      <section className={styles.row}>
        <CtoBoardroomMediaAward />
        <InsightsAward />
        <StevieAward />
        <G2Award />
        <FastCompanyAward />
      </section>
    </article>
  );
}
